import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
  EntitiesModel
} from '@spartacus/core';
import {
  DoehlerProductWishList, DoehlerProductWishListData
} from '../../core/model/wishlist.model';


@Injectable({
  providedIn: 'root',
})
export class OccDoehlerWishListsProductPagedListNormalizer
  implements Converter<DoehlerProductWishList, EntitiesModel<DoehlerProductWishListData>> {
  constructor(private converter: ConverterService) {}

  convert(
    source: DoehlerProductWishList,
    target?: EntitiesModel<DoehlerProductWishListData>
  ): EntitiesModel<DoehlerProductWishListData> {
    if (target === undefined) {
      target = {
        values: source.products,
      };
    }
    return target;
  }
}


