import { InjectionToken } from '@angular/core';
import { Converter, EntitiesModel } from '@spartacus/core';
import {
    DoehlerProductWishListData,
    DoehlerWishListData,
    DoehlerWishListMediaListData,
    DoehlerWishListSliderListData
} from 'src/app/doehler-custom-wishlist/core/model/wishlist.model';

//whishlist one converter
export const WISHLIST_NORMALIZER = new InjectionToken<
  Converter<any, DoehlerWishListData>
>('DoehlerWishListNormalizer');
//whishlist array converter
export const WISHLISTS_NORMALIZER = new InjectionToken<
  Converter<any, EntitiesModel<DoehlerWishListData>>
>('DoehlerWishListsListNormalizer');
//whishlistmedia array converter
export const WISHLISTS_MEDIALIST_NORMALIZER = new InjectionToken<
    Converter<any, EntitiesModel<DoehlerWishListMediaListData>>
    >('DoehlerWishListMediaListListNormalizer');
//whishlistslider array converter
export const WISHLISTS_SLIDERLIST_NORMALIZER = new InjectionToken<Converter<any, EntitiesModel<DoehlerWishListSliderListData>>>('DoehlerWishListSliderListListNormalizer');
//whishlistpaged array converter
export const WISHLISTS_PRODUCTPAGEDLIST_NORMALIZER = new InjectionToken<Converter<any, EntitiesModel<DoehlerProductWishListData>>>('DoehlerProductWishListNormalizer');
