import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
  EntitiesModel
} from '@spartacus/core';
import { WISHLIST_NORMALIZER } from '../../core/connectors/converters';
import { DoehlerWishListData, DoehlerWishListList } from '../../core/model/wishlist.model';

@Injectable({
  providedIn: 'root',
})
export class OccDoehlerWishListsListNormalizer
  implements Converter<DoehlerWishListList, EntitiesModel<DoehlerWishListData>> {
  constructor(private converter: ConverterService) {}

  convert(
    source: DoehlerWishListList,
    target?: EntitiesModel<DoehlerWishListData>
  ): EntitiesModel<DoehlerWishListData> {
    if (target === undefined) {
      //console.log("OccDoehlerWishListsListNormalizer");
      target = {
        values: source.wishlists,
      };
    }
    return target;
  }
}


