import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
    BrowserModule
} from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { translationChunksConfig, translations } from '@spartacus/assets';
import {
    BaseSiteService,
    FeatureToggles,
    I18nConfig,
    OccConfig,
    provideConfig, provideFeatureToggles, RoutingConfig, SearchConfig
} from '@spartacus/core';
import { AppRoutingModule, BaseStorefrontModule, StorefrontComponent } from "@spartacus/storefront";
import { OAuthModule } from "angular-oauth2-oidc";
import { ClickOutsideModule } from 'ng-click-outside';
import { environment } from "../environments/environment";
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';

const devImports = [];
if (!environment.production) {
    devImports.push(StoreDevtoolsModule.instrument());
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'spartacus-app' }),
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        SpartacusModule,
        OAuthModule.forRoot(),
        ClickOutsideModule,
        BaseStorefrontModule,
        ...devImports,
    ],
    providers: [
        BaseSiteService,
        provideConfig(<OccConfig>{
            backend: {
                occ: {
                    baseUrl: environment.occBaseUrl,
                    prefix: '/occ/v2/'
                },
            },
            context: {
                baseSite: ['dCatalog'],
            },
        }),
        provideConfig(<RoutingConfig>{
            // custom routing configuration for e2e testing
            routing: {
              routes: {
                product: {
                  paths: ['product/:productCode/:name', 'product/:productCode'],
                  paramsMapping: { name: 'slug' },
                },
              },
            },
          }),
        provideConfig(<I18nConfig>{
            i18n: {
                resources: translations,
                chunks: translationChunksConfig,
                fallbackLang: 'en',
            },
        }),
        provideFeatureToggles(<FeatureToggles>{
            features: {
                level: '*',
            },
        }),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], // Added to suppress errors for custom elements like cx-storefront
    bootstrap: [StorefrontComponent],
})
export class AppModule {
}

export interface DoehlerWishListSearchConfig extends SearchConfig {
    customerUserId?: string;
    companyId?: string;
    page?: string;
}