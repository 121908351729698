import { Injectable } from '@angular/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { Observable, using } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { DoehlerProductSearchPage } from './doehler-product-search.model';

@Injectable({ providedIn: 'root' })
export class CustomProductListComponentService extends ProductListComponentService {

  defaultPageSize = 12;


  protected searchResults$: Observable<
  DoehlerProductSearchPage
> = this.productSearchService
  .getResults()
  .pipe(filter((searchResult) => Object.keys(searchResult).length > 0));


  model$: Observable<DoehlerProductSearchPage> = using(
    () => this.searchByRouting$.subscribe(),
    () => this.searchResults$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

}
