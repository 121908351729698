import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const categoryTranslations: TranslationResources = {
  en,
};

export const categoryTranslationChunksConfig: TranslationChunksConfig = {
  category: ['category'],
};
