import { Injectable } from "@angular/core";
import { FacetListComponent } from "@spartacus/storefront";
import { Observable } from "rxjs";
import { DoehlerFacetList } from "./doehler-product-search.model";

@Injectable({ providedIn: 'root' })
export class DoehlerFacetListComponent extends FacetListComponent {

 /** The list of all facet and values related to the products in the list */
 facetList$: Observable<DoehlerFacetList>;
 
}