<div class="content-wrapper">

  <div id="filterFlyIn">
    <div class="header">
      <h2>Filter</h2>
      <div class="resetLink" (click)="toggleFilterBar()">reset</div>
    </div>

    <section>
      <h3>Application Fit</h3>

      <div class="radioButtonContainer">
        <ng-container *ngFor="let option of applicationFits">
          <label for="applicationFit-{{option.value}}" class="radio-container">{{ option.label }}
          <input type="radio" id="applicationFit-{{option.value}}" name="applicationFit" [value]="option.value">
          <span class="checkmark"></span>
          </label>
        </ng-container>
      </div>

    </section>
    <section>
      <h3>Colour Shade</h3>
      <div class="checkbox-container group">
        <ng-container *ngFor="let option of colourShades">
          <label for="colourShade-{{option.value}}">{{ option.label }}
            <input type="checkbox" id="colourShade-{{option.value}}" name="colourShade">
            <span class="checkmark" [ngStyle]="setBackgroundColour(option.code)"></span>
          </label>
        </ng-container>
      </div>
    </section>
    <section>
      <h3>Regulatory Info</h3>
      <h4>Raw material source</h4>
      <div class="checkbox-container group">
        <ng-container *ngFor="let option of regulatoryInfoRaw">
          <label for="colourShade-{{option.value}}">{{ option.label }}
            <input type="checkbox" id="colourShade-{{option.value}}2" name="colourShade">
            <span class="checkmark"></span>
          </label>
        </ng-container>
      </div>

      <div class="spacer"></div>
      
      <h4>More</h4>
      <div class="checkbox-container group">
        <ng-container *ngFor="let option of regulatoryInfoMore">
          <label for="colourShade-{{option.value}}">{{ option.label }}
            <input type="checkbox" id="colourShade-{{option.value}}" name="colourShade">
            <span class="checkmark"></span>
          </label>
        </ng-container>
      </div>
      
    </section>
    <section>
      <h3>Physical & Chemical Characteristics</h3>
      <div class="characteristicsContainer">
        <div class="row">
          <div class="characteristicsSlider">
            <label for="colourUnit">Colour Unit (E1 5, 1cm)</label>
            <input type="range" id="volume" name="colourUnit" min="1" max="100" value="1">
            <div class="min">
              1
            </div>
            <div class="max">
              100
            </div>
          </div>
          <div class="characteristicsSlider">
            <label for="cloudiness">Cloudiness</label>
            <input type="range" id="volume" name="cloudiness" min="100" max="900" value="100">
            <div class="min">
              100
            </div>
            <div class="max">
              900
            </div>
          </div>
        </div>
        <div class="row">
          <div class="characteristicsSlider">
            <label for="colourContent">Colour Content (in EBC)</label>
            <input type="range" id="volume" name="colourContent" min="5000" max="20000" value="5000">
            <div class="min">
              5.000
            </div>
            <div class="max">
              20.000
            </div>
          </div>
          <div class="characteristicsSlider">
            <label for="caroteneContent">Carotene Content (in %)</label>
            <input type="range" id="volume" name="caroteneContent" min="0" max="100"  value="0">
            <div class="min">
              0
            </div>
            <div class="max">
              100
            </div>
          </div>
        </div>
      </div>
      
    </section>
    <section class="noBorder">
      <h3>Target Market</h3>
      <div class="radioButtonContainer">
        <label for="targetMarket-europe" class="radio-container">EUR
        <input type="radio" id="targetMarket-europe" name="targetMarket" value="eur">
        <span class="checkmark"></span>
        </label>
      </div>
    </section>

    <section class="buttonRow">
      <button class="applyButton" (click)="toggleFilterBar()">apply filter <fa-icon [icon]="faAngleDoubleRight"></fa-icon></button>
    </section>

  </div>

  <section class="product-filter">
    <div class="inner">

      <h2>{{headline}}</h2>

      <div class="seperation-section">

        <div class="tabs-row">
          <ng-container *ngFor="let productGroup of productData">
            <div class="tab" [ngClass]="{'active': productGroup.title === groupTabToShow}" (click)="showGroupTab(productGroup.title)">
              {{productGroup.title}}
            </div>
          </ng-container>
        </div>

        <div class="tabs-content outer">

          <div class="filterRow">
            <div class="filterButton" (click)="toggleFilterBar()">
              Filter <fa-icon [icon]="faFilter"></fa-icon>
            </div>
            <div class="appliedFilter">
              <div class="appliedFilterItem">Non-Alcoholic Beverages</div>
              <div class="appliedFilterItem">Black Carrot</div>
              <div class="appliedFilterItem">Malt</div>
              <div class="appliedFilterItem">Beta Carotne</div>
            </div>
            <div class="sortDropdown">
              <div class="select-container">
                <select id="sortItems">
                  <option value="" selected>sort by</option>
                  <option *ngFor="let item of sortItems" [value]="item.value">
                      {{ item.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let productGroup of productData">
            <div class="tabs-row inner" *ngIf="productGroup.title  === groupTabToShow">
              <ng-container *ngFor="let taste of productGroup.contents">
                <div class="tab" [ngClass]="{'active': taste.title === tasteTabToShow}" (click)="showTasteTab(taste.title)">
                  {{taste.title}}
                </div>
              </ng-container>
            </div>

            <div class="tabs-content" *ngIf="productGroup.title === groupTabToShow">
              <ng-container *ngFor="let taste of productGroup.contents">
                <div class="content-area" *ngIf="tasteTabToShow === taste.title">
                  <div class="row">
                    <ng-container *ngFor="let product of taste.products">
                      <div class="item-box">
                        <div class="image" [ngStyle]="setBackgroundImage(product.productImage)"></div>
                        <div class="details">
                          <h3>{{product.title}}</h3>
                          <div class="product-id">{{product.productNumber}}</div>
                          <div class="attribute-list">
                            <ul>
                              <li *ngFor="let attribute of product.mainAttributes">
                                <div class="attribute-icon">
                                  <img src="{{attribute.iconSVG}}">
                                </div>
                                <div class="attribute-value">
                                  {{attribute.label}}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                </div>
              </ng-container>
            </div>

          </ng-container>

        </div>
      </div>

    </div>
  </section>
</div>
