import { Injectable } from '@angular/core';
import { EntitiesModel } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DoehlerWishListSearchConfig } from 'src/app/app.module';
import {
  DoehlerProductWishListData,
  DoehlerWishListData,
  DoehlerWishListMediaListData,
  DoehlerWishListSliderListData
} from 'src/app/doehler-custom-wishlist/core/model/wishlist.model';
import { DoehlerWishlistAdapter } from './wishlist.adapter';

@Injectable({
  providedIn: 'root',
})
export class WhishlistConnector {
  constructor(protected adapter: DoehlerWishlistAdapter) {}

  get(userid: string,pk:string): Observable<DoehlerWishListData> {
    return this.adapter.load(userid,pk);
  }

  getList(
    userId: string,
    companyId: string,
    params?: DoehlerWishListSearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>> {

    return this.adapter.loadList(userId,companyId, params);
  }

  getPopupList(
    userId: string,
    companyId: string,
    params?: DoehlerWishListSearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>> {

    return this.adapter.loadPopupList(userId,companyId, params);
  }

  getListMediaList(
      userId: string,
      companyId: string,
      params?: DoehlerWishListSearchConfig
  ): Observable<EntitiesModel<DoehlerWishListMediaListData>> {

    return this.adapter.loadListMediaList(userId,companyId, params);
  }

  getListSliderList(
      userId: string,
      companyId: string,
      params?: DoehlerWishListSearchConfig
  ): Observable<EntitiesModel<DoehlerWishListSliderListData>> {

    return this.adapter.loadListSliderList(userId, companyId, params);
  }

  getProductPagedList(
      userId: string,
      companyId: string,
      params?: DoehlerWishListSearchConfig
  ): Observable<EntitiesModel<DoehlerProductWishListData>> {
    return this.adapter.loadProductPagedList(userId, companyId, params);
  }
}
