import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  EntitiesModel,
  OccEndpointsService,
  SearchConfig
} from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  WISHLISTS_MEDIALIST_NORMALIZER, WISHLISTS_NORMALIZER,
  WISHLIST_NORMALIZER, WISHLISTS_SLIDERLIST_NORMALIZER, WISHLISTS_PRODUCTPAGEDLIST_NORMALIZER
} from '../../core/connectors/converters';
import { DoehlerWishlistAdapter } from '../../core/connectors/wishlist.adapter';
import {
  DoehlerProductWishListData,
  DoehlerWishListData,
  DoehlerWishListList,
  DoehlerWishListMediaListData,
  DoehlerWishListMediaListList,
  DoehlerWishListSliderListData,
  DoehlerWishListSliderListList
} from '../../core/model/wishlist.model';


@Injectable()
export class OccDoehlerWishListAdapter implements DoehlerWishlistAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  load(userid: string, pk: string): Observable<DoehlerWishListData> {
    return this.http
    .get<DoehlerWishListData>(
      this.getDoehlerWishListEndpoint(pk)
    )
    .pipe(this.converter.pipeable(WISHLIST_NORMALIZER));

  }

  loadList(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>> {
    return this.http
    .get<DoehlerWishListList>(
      this.getDoehlerWishListsEndpoint(userId,companyId, params)
    ).pipe(this.converter.pipeable(WISHLISTS_NORMALIZER));
  }

  loadPopupList(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>> {
    return this.http
    .get<DoehlerWishListList>(
      this.getDoehlerWishListsPopupEndpoint(userId,companyId, params)
    ).pipe(this.converter.pipeable(WISHLISTS_NORMALIZER));
  }

  loadListMediaList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListMediaListData>> {
    return this.http
        .get<DoehlerWishListMediaListList>(
            this.getDoehlerWishListsMediaListEndpoint(userId,companyId, params)
        ).pipe(this.converter.pipeable(WISHLISTS_MEDIALIST_NORMALIZER));
  }

  loadListSliderList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListSliderListData>> {
    return this.http
        .get<DoehlerWishListSliderListList>(
            this.getDoehlerWishListsSliderListEndpoint(userId, companyId, params)
        ).pipe(this.converter.pipeable(WISHLISTS_SLIDERLIST_NORMALIZER));
  }

  loadProductPagedList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerProductWishListData>> {
    return this.http
        .get<DoehlerProductWishListData>(
            this.getDoehlerWishListsProductPagedListEndpoint(userId, companyId, params)
        ).pipe(this.converter.pipeable(WISHLISTS_PRODUCTPAGEDLIST_NORMALIZER));
  }

  protected getDoehlerWishListEndpoint(
    pk:string,
  ): string {
    return this.occEndpoints.buildUrl('wishList/details',  { urlParams: { pk } });
  }

  protected getDoehlerWishListsEndpoint(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/list',  { urlParams: { userId,companyId } ,queryParams: params});
  }

  protected getDoehlerWishListsPopupEndpoint(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/list-popup', {urlParams: {userId, companyId}, queryParams: params});
  }

  protected getDoehlerWishListsMediaListEndpoint(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/wishlistmedialist',  { urlParams: { userId,companyId },queryParams: params });
  
  }

  protected getDoehlerWishListsSliderListEndpoint(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/slider-list', {urlParams: {userId, companyId}, queryParams: params});

  }

   protected getDoehlerWishListsProductPagedListEndpoint(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/paged-list', {urlParams: {userId, companyId}, queryParams: params});

  }
  
}
