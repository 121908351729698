import { PaginationModel, Product, SearchConfig, SortModel } from "@spartacus/core";


export interface DoehlerWishListList {
   wishlists?:DoehlerWishListData[];
   pagination?:PaginationModel;
   sorts?:SortModel[];
}

export interface DoehlerProductWishList {
   products?:DoehlerProductWishListData[];
   pagination?:PaginationModel;
   sorts?:SortModel[];
}

export interface DoehlerWishListMediaListList {
    wishlistmedialist?:DoehlerWishListMediaListData[];
    pagination?:PaginationModel;
    sorts?:SortModel[];
}

export interface DoehlerWishListSliderListList {
    wishlistSlider?:DoehlerWishListSliderListData[];
    pagination?:PaginationModel;
    sorts?:SortModel[];
}

export interface DoehlerWishListData {
  name: string;
  pk: string;
  site: string;
  creator : CreatorData;
  owners : OwnersData[];
  products : Product[];
  modifiedDate:string;
  companyId:string;
  paginationProducts: PaginationProducts[];
}

export interface DoehlerProductWishListData {
    pk:string;
    name: string;
    code: string;
    wishlist?: DoehlerWishListInfoData;
    pagination?: PaginationModel;
}

export interface DoehlerWishListInfoData{
  name: string;
  pk: string;
  site: string;
  modifiedDate:string;
  companyId:string;
}

export interface DoehlerWishListMediaListData {
    mediaFormat: string;
    mediaPath: string;
}

export interface DoehlerWishListSliderListData {
    pk: string;
    name: string;
    productSize: string;
}

interface PaginationProducts{
    key: number;
    value : ProductListPagination[];
}

interface  ProductListPagination{
    productList: Product[];
}
interface CreatorData{
  name : string;
  uid : string;
}

interface OwnersData{
  name : string;
  uid : string;
}