import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';

@Component({
  selector: 'doehler-section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.scss']
})
export class Section1Component implements OnInit {

  product$: Observable<Product> = this.currentProductService.getProduct();
  constructor(private currentProductService: CurrentProductService) { }
  ngOnInit(): void {
  }

}
