import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthConfigService, AuthStorageService } from "@spartacus/core";
import { Observable } from "rxjs";
import { switchMap, take } from "rxjs/operators";

/**
 * This interceptor is dedicated for Hybris OAuth server which requires `Authorization` header for revoke token calls.
 */
 declare let gigya: any;
 declare global {
   interface Window {
     onGigyaServiceReady: any;
   }
 }
 @Injectable({ providedIn: 'root' })
 export class DoehlerTokenRevocationInterceptor implements HttpInterceptor {

  gigyaReady: Promise<void>;

   constructor(
     protected authStorageService: AuthStorageService,
     protected authConfigService: AuthConfigService
   ) {


      //set callback for the CDC Web SDK loading event and resolve promise
    this.gigyaReady = new Promise((resolve, reject) => {

      window.onGigyaServiceReady = function () {

        resolve();

      };

    })

    this.gigyaReady.then(() => {

      // set active organization

      if (typeof gigya !== 'undefined') {

        gigya.accounts.b2b.getOrganizationContext({

          callback: function (res) {
            localStorage.setItem('spartacus-accountID', res.bpId);
          },

        });

      } 

    });

   }
 
   intercept(
     request: HttpRequest<any>,
     next: HttpHandler
   ): Observable<HttpEvent<any>> {
     const isTokenRevocationRequest = this.isTokenRevocationRequest(request);
     
     return this.authStorageService.getToken().pipe(
       take(1),
       switchMap((token) => {
         if (isTokenRevocationRequest) {
          
           request = request.clone({
             setHeaders: {
               Authorization: `${token.token_type || 'Bearer'} ${
                 token.access_token
               }`,
             },
           });
         }
         return next.handle(request);
       })
     );
   }
 
   protected isTokenRevocationRequest(request: HttpRequest<any>): boolean {
     return request.url === this.authConfigService.getRevokeEndpoint();
   }
 }
 