import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
  EntitiesModel
} from '@spartacus/core';
import {
  DoehlerWishListMediaListData,
  DoehlerWishListMediaListList
} from '../../core/model/wishlist.model';

@Injectable({
  providedIn: 'root',
})
export class OccDoehlerWishListsMediaListNormalizer
  implements Converter<DoehlerWishListMediaListList, EntitiesModel<DoehlerWishListMediaListData>> {
  constructor(private converter: ConverterService) {}

  convert(
    source: DoehlerWishListMediaListList,
    target?: EntitiesModel<DoehlerWishListMediaListData>
  ): EntitiesModel<DoehlerWishListMediaListData> {
    if (target === undefined) {
      //console.log("OccDoehlerWishListsMediaListNormalizer");
      target = {
        values: source.wishlistmedialist,
      };
    }
    return target;
  }
}


