import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
} from '@spartacus/core';
import { WISHLIST_NORMALIZER } from '../../core/connectors/converters';
import { DoehlerWishListData } from '../../core/model/wishlist.model';

@Injectable({
  providedIn: 'root',
})
export class OccDoehlerWishListNormalizer
  implements Converter<DoehlerWishListData, DoehlerWishListData> {
  constructor(private converter: ConverterService) {}

  convert(source: DoehlerWishListData, target?: DoehlerWishListData): DoehlerWishListData {
    if (target === undefined) {
      target = {
        ...(source as any),
      };
        target = source;
    }
    return target;
  }
}
