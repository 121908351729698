import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ActivatedRouterStateSnapshot, PageType, ProductSearchPage } from '@spartacus/core';
import { ProductFacetService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import { CustomProductListComponentService } from './CustomProductListComponentService';
import { DoehlerBreadcrumb, DoehlerFacetList, DoehlerProductSearchPage } from './doehler-product-search.model';

@Injectable({ providedIn: 'root' })
export class DoehlerProductFacetService extends ProductFacetService {

   protected routeState$ = this.routing
   .getRouterState()
   .pipe(pluck('state'));

   protected productListComponentService: CustomProductListComponentService;

 /**
  * Observes the facets and active facets for the given page. The facet data
  * is provided in a `FacetList`.
  */
facetList$: Observable<DoehlerFacetList> = this.searchResult$.pipe(
   map(
     (result: DoehlerProductSearchPage) =>
       ( 
         {
         facets: result.facets,
         activeFacets: result.breadcrumbs,
       } as DoehlerFacetList)
   )
 );
 
 protected searchResult$: Observable<
 ProductSearchPage
> = this.routeState$.pipe(
 switchMap((state) =>
   this.productListComponentService.model$.pipe(
     filter((page) => this.filterForPage(state, page)),
     map((page) => this.customMapResults(state, page))
   )
 )
);
private customMapResults(
  state: ActivatedRouterStateSnapshot,
  page: DoehlerProductSearchPage
): DoehlerProductSearchPage {
  return {
    ...page,
    breadcrumbs: this.customFilterBreadcrumbs(page.breadcrumbs, state.params),
  };
}
 
 /**
  * Filters the current result by verifying if the result is related to the page.
  * This is done to avoid a combination of the next page and the current search results.
  */
 protected filterForPage(
   state: ActivatedRouterStateSnapshot,
   page: DoehlerProductSearchPage
 ): boolean {
   if (state.context.type === PageType.CATEGORY_PAGE) {
     return (
       page.currentQuery?.query?.value?.indexOf(
         `allCategories:${state.context.id}`
       ) > -1
     );
   }

   if (
     state.context.type === PageType.CONTENT_PAGE &&
     state.context.id === 'search'
   ) {
     return page.currentQuery.query.value.startsWith(`${state.params.query}:`);
   }
   return false;
 }


/**
 * filter breadcrumbs which are not actively selected
 * but coming from the route navigation
 */
private customFilterBreadcrumbs(
  breadcrumbs: DoehlerBreadcrumb[],
  params: Params
): DoehlerBreadcrumb[] {
  return breadcrumbs
    ? breadcrumbs.filter(
        (breadcrumb) => !this.customHasBreadcrumb(breadcrumb, params)
      )
    : [];
}
  /**
   * Indicates whether the breadcrumb is related to navigation parameters,
   * since either the category or brand code should match those codes.
   */
  private customHasBreadcrumb(breadcrumb: DoehlerBreadcrumb, params: Params): boolean {
   
    return (
      breadcrumb.facetCode === 'allCategories' &&
      (breadcrumb.facetValueCode === params.categoryCode ||
        breadcrumb.facetValueCode === params.brandCode)
    );
  }

}
