import { StateUtils } from '@spartacus/core';
import {DoehlerWishListData, DoehlerWishListList, DoehlerWishListMediaListData, DoehlerWishListSliderListData, DoehlerProductWishListData} from '../../model/wishlist.model';
import { WishlistActions } from '../actions/index';
import {LOAD_WISHLIST_MEDIALIST_SUCCESS, LOAD_WISHLIST_PRODUCTPAGEDLIST_SUCCESS, LOAD_WISHLISTS_MEDIALIST_SUCCESS, LOAD_WISHLISTS_PRODUCTPAGEDLIST_SUCCESS} from "../actions/wishlist.actions";


export const wishlistInitialState = undefined;
export const wishlistMediaListInitialState = undefined;
export const wishlistSliderListInitialState = undefined;
export const wishlistProductPagedListInitialState = undefined;
export const wishlistsInitialState = undefined;
export const wishlistsMediaInitialState = undefined;
export const wishlistsSliderInitialState = undefined;
export const wishlistsProductPagedListInitialState = undefined;


export function wishlistEntitiesReducer(
  state: DoehlerWishListData = wishlistInitialState,
  action: StateUtils.LoaderAction
): DoehlerWishListData {
  switch (action.type) {
    case WishlistActions.LOAD_WISHLIST_SUCCESS:
      return action.payload;
  
  }
  return state;
}

export function wishlistMediaListEntitiesReducer(
    state: DoehlerWishListMediaListData = wishlistMediaListInitialState,
    action: StateUtils.LoaderAction
): DoehlerWishListMediaListData {
  switch (action.type) {
    case WishlistActions.LOAD_WISHLIST_MEDIALIST_SUCCESS:
      return action.payload;
  }
  return state;
}

export function wishlistSliderListEntitiesReducer(
    state: DoehlerWishListSliderListData = wishlistSliderListInitialState,
    action: StateUtils.LoaderAction
): DoehlerWishListSliderListData {
  switch (action.type) {
    case WishlistActions.LOAD_WISHLIST_SLIDERLIST_SUCCESS:
      return action.payload;
  }
  return state;
}

export function wishlistProductPagedListEntitiesReducer(
    state: DoehlerProductWishListData = wishlistProductPagedListInitialState,
    action: StateUtils.LoaderAction
): DoehlerProductWishListData {
  switch (action.type) {
    case WishlistActions.LOAD_WISHLIST_PRODUCTPAGEDLIST_SUCCESS:
      return action.payload;
  }
  return state;
}

export function wishListListReducer(
  state = wishlistsInitialState,
  action: StateUtils.LoaderAction
): any {
  switch (action.type) {

    case WishlistActions.LOAD_WISHLISTS_SUCCESS:
      return action.payload.page;
  }

  return state;
}

export function wishListListMediaReducer(
    state = wishlistsMediaInitialState,
    action: StateUtils.LoaderAction
): any {
  switch (action.type) {

    case WishlistActions.LOAD_WISHLISTS_MEDIALIST_SUCCESS:
      return action.payload.page;
  }

  return state;
}

export function wishListListSliderReducer(
    state = wishlistsSliderInitialState,
    action: StateUtils.LoaderAction
): any {
  switch (action.type) {

    case WishlistActions.LOAD_WISHLISTS_SLIDERLIST_SUCCESS:
      return action.payload.page;
  }

  return state;
}

export function wishListListProductPagedReducer(
    state = wishlistsProductPagedListInitialState,
    action: StateUtils.LoaderAction
): any {
  switch (action.type) {

    case WishlistActions.LOAD_WISHLISTS_PRODUCTPAGEDLIST_SUCCESS:
      return action.payload.page;
  }

  return state;
}
