import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EntitiesModel, OCC_USER_ID_ANONYMOUS, StateUtils } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {
    DoehlerProductWishListData,
    DoehlerWishListData, DoehlerWishListMediaListData, DoehlerWishListSliderListData
} from 'src/app/doehler-custom-wishlist/core/model/wishlist.model';
import { WhishlistConnector } from '../../connectors/wishlist.connector';
import { WishlistActions } from '../actions/index';
import {DoehlerBreadcrumb, DoehlerFacetList} from "../../../../doehler-custom-category/custom-category-list/doehler-product-search.model";


@Injectable()
export class WhishlistEffects {


  
  loadWhishList$: Observable<
    | WishlistActions.LoadWishlistSuccess
    | WishlistActions.LoadWishlistFail
  > = createEffect(() => this.actions$.pipe(
    ofType(WishlistActions.LOAD_WISHLIST),
    map((action: WishlistActions.LoadWishlist) => action.payload),
    filter((payload) => payload.userId !== OCC_USER_ID_ANONYMOUS),
    switchMap(({ userId, pk }) => {
      return this.whishlistConnector.get(userId,pk).pipe(
        map((pk: DoehlerWishListData) => {
          return new WishlistActions.LoadWishlistSuccess([
            pk,
          ]);
        }),
        catchError((error: HttpErrorResponse) =>
          of(
            new WishlistActions.LoadWishlistFail({
              userId,
              pk,
              error: error
            })
          )
        )
      );
    })
  ));

    
    loadWishListPopup$: Observable<| WishlistActions.LoadWishlistsSuccess
        | WishlistActions.LoadWishlistSuccess
        | WishlistActions.LoadWishlistsFail> = createEffect(() => this.actions$.pipe(
        ofType(WishlistActions.LOAD_WISHLISTS_POPUP),
        map((action: WishlistActions.LoadWishlistsPopup) => action.payload),
        filter((payload) => payload.userId !== OCC_USER_ID_ANONYMOUS),
        switchMap(({params}) =>
            this.whishlistConnector.getPopupList(params.customerUserId, params.companyId, params).pipe(
                switchMap((whishlists: EntitiesModel<DoehlerWishListData>) => {
                    const {values, page} = StateUtils.normalizeListPage(
                        whishlists,
                        'pk'
                    );
                    return [
                        new WishlistActions.LoadWishlistSuccess(values),
                        new WishlistActions.LoadWishlistsSuccess({
                            page,
                            params,
                        }),
                    ];
                }),
                catchError((error: HttpErrorResponse) =>
                    of(
                        new WishlistActions.LoadWishlistsFail({
                            params: params,
                            error: error,
                        })
                    )
                )
            )
        )
    ));

  
  loadWishListList$: Observable<
  | WishlistActions.LoadWishlistsSuccess
    | WishlistActions.LoadWishlistSuccess
    | WishlistActions.LoadWishlistsFail
  > = createEffect(() => this.actions$.pipe(
    ofType(WishlistActions.LOAD_WISHLISTS),
    map((action: WishlistActions.LoadWishlists) => action.payload),
    filter((payload) => payload.userId !== OCC_USER_ID_ANONYMOUS),
    switchMap(({ params }) =>
      this.whishlistConnector.getList(params.customerUserId, params.companyId,params).pipe(
        switchMap((whishlists: EntitiesModel<DoehlerWishListData>) => {
          const { values, page } = StateUtils.normalizeListPage(
            whishlists,
            'pk'
          );
          return [
            new WishlistActions.LoadWishlistSuccess(values),
            new WishlistActions.LoadWishlistsSuccess({
              page,
              params,
            }),
          ];
        }),
        catchError((error: HttpErrorResponse) =>
          of(
            new WishlistActions.LoadWishlistsFail({
              params: params,
              error: error,
            })
          )
        )
      )
    )
  ));

    
    loadWishListListMediaList$: Observable<
        | WishlistActions.LoadWishlistsMediaListSuccess
        | WishlistActions.LoadWishlistMediaListSuccess
        | WishlistActions.LoadWishlistsMediaListFail
        > = createEffect(() => this.actions$.pipe(
        ofType(WishlistActions.LOAD_WISHLISTSMEDIALIST),
        map((action: WishlistActions.LoadWishlistsMediaList) => action.payload),
        filter((payload) => payload.userId !== OCC_USER_ID_ANONYMOUS),
        switchMap(({ params }) =>
            this.whishlistConnector.getListMediaList(params.customerUserId, params.companyId,params).pipe(
                switchMap((wishlistmedialist: EntitiesModel<DoehlerWishListMediaListData>) => {
                    const { values, page } = StateUtils.normalizeListPage(
                        wishlistmedialist,
                        'mediaPath'
                    );
                    return [
                        new WishlistActions.LoadWishlistMediaListSuccess(values),
                        new WishlistActions.LoadWishlistsMediaListSuccess({
                            page,
                            params,
                        }),
                    ];
                }),
                catchError((error: HttpErrorResponse) =>
                    of(
                        new WishlistActions.LoadWishlistsMediaListFail({
                            params: params,
                            error: error,
                        })
                    )
                )
            )
        )
    ));

    loadWishListListSliderList$: Observable<
        | WishlistActions.LoadWishlistsSliderListSuccess
        | WishlistActions.LoadWishlistSliderListSuccess
        | WishlistActions.LoadWishlistsSliderListFail
        > = createEffect(() => this.actions$.pipe(
        ofType(WishlistActions.LOAD_WISHLISTSSLIDERLIST),
        map((action: WishlistActions.LoadWishlistsSliderList) => action.payload),
        filter((payload) => payload.userId !== OCC_USER_ID_ANONYMOUS),
        switchMap(({ params }) =>
            this.whishlistConnector.getListSliderList(params.customerUserId, params.companyId,params).pipe(
                switchMap((wishlistSlider: EntitiesModel<DoehlerWishListSliderListData>) => {
                    const { values, page } = StateUtils.normalizeListPage(
                        wishlistSlider,
                        'pk'
                    );
                    return [
                        new WishlistActions.LoadWishlistSliderListSuccess(values),
                        new WishlistActions.LoadWishlistsSliderListSuccess({
                            page,
                            params,
                        }),
                    ];
                }),
                catchError((error: HttpErrorResponse) =>
                    of(
                        new WishlistActions.LoadWishlistsSliderListFail({
                            params: params,
                            error: error,
                        })
                    )
                )
            )
        )
    ));

    loadWishListListProductPagedList$: Observable<| WishlistActions.LoadWishlistProductPagedListSuccess | WishlistActions.LoadWishlistsProductPagedListSuccess
        | WishlistActions.LoadWishlistsProductPagedListFail> = createEffect(() => this.actions$.pipe(
        ofType(WishlistActions.LOAD_WISHLISTSPRODUCTPAGEDLIST),
        map((action: WishlistActions.LoadWishlistsProductPagedList) => action.payload),
        filter((payload) => payload.userId !== OCC_USER_ID_ANONYMOUS),
        switchMap(({params}) =>
            this.whishlistConnector.getProductPagedList(params.customerUserId, params.companyId, params).pipe(
                switchMap((wishlistProductPaged: EntitiesModel<DoehlerProductWishListData>) => {
                    const {values, page} = StateUtils.normalizeListPage(
                        wishlistProductPaged,'pk'
                    );
                    return [
                        new WishlistActions.LoadWishlistProductPagedListSuccess(values),
                        new WishlistActions.LoadWishlistsProductPagedListSuccess({
                            page,
                            params,
                        }),
                    ];
                }),
                catchError((error: HttpErrorResponse) =>
                    of(
                        new WishlistActions.LoadWishlistsProductPagedListFail({
                            params: params,
                            error: error,
                        })
                    )
                )
            )
        )
    ));


  constructor(
    private actions$: Actions,
    private whishlistConnector: WhishlistConnector
  ) {}
}
