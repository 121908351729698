import { OccConfig } from '@spartacus/core';

export const doehlerOccProductDetailConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          default:
            'products/${productCode}?fields=FULL,productBenefits,doehlerBenefits,doehlerCatalogConfigData,applicationFitCategories,isFavourite,wishListDCatalog',
          list: 'products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),baseProduct',
          details:
            'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,images(FULL)',
          attributes: 'products/${productCode}?fields=classifications',
          price: 'products/${productCode}?fields=price(formattedValue)',
        },

        productReviews: 'products/${productCode}/reviews',
        // Uncomment this when occ gets configured
        // productReferences:
        //   'products/${productCode}/references?fields=DEFAULT,references(target(images(FULL)))&referenceType=${referenceType}',
        productReferences:
            'products/${productCode}?fields=FULL,productBenefits,doehlerBenefits,doehlerCatalogConfigData,applicationFitCategories,isFavourite,wishListDCatalog',
        /* eslint-disable max-len */
        productSearch:
            'products/search?fields=FULL,doehlerCatalogConfigData,categoryCatalogTabInfo,pageName,defaultActiveTabName,categoriesMediaList,attributeGroups,wishListDCatalog,targetMarketMappingData',
        /* eslint-enable */
        productSuggestions: 'products/suggestions',
      },
      
    },
  },
};