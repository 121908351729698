import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'doehler-custom-bottom-header',
  templateUrl: './custom-bottom-header.component.html',
  styleUrls: ['./custom-bottom-header.component.scss']
})
export class CustomBottomHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
