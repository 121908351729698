
export class DoehlerFunctionUtil {
  

  static fnFacetSelectedCount(facetList){
    if(!facetList) {
      return;
    }
    const countDict = facetList.activeFacets.reduce((acc, curr) => {
      const { facetCode } = curr;
      if (acc[facetCode]) ++acc[facetCode];
      else acc[facetCode] = 1;
      return acc;
    }, {});
   
    return countDict;
}

static fnRegulatoryInfoSelectedCount(facet){
  if(!facet.doehlerCatalogConfigData) {
    return;
  }
  var count = 0;

  const countDict = facet.doehlerCatalogConfigData.specificationsRegulatoryInfoDCatalog.reduce((acc, curr) => {   
    const code = "regulatoryInfoSelectedCount";
    if(!curr.values) {
      return;
    }
    curr.values.forEach(function (value) {
      if (value.selected) {
        ++count;
      }
  });
  acc[code] = count;
    return acc;
  }, {});
  return countDict;
}

static fnPhysicalChemicalCharSelectedCount(facet){
  if(!facet.doehlerCatalogConfigData) {
    return;
  }
  var count = 0;

  const countDict = facet.doehlerCatalogConfigData.specificationsPhysicalChemicalCharacteristicsDCatalog.reduce((acc, curr) => {   
    const code = "physicalChemicalCharSelectedCount";
    if(!curr.values) {
      return;
    }

    if (curr.fancyFacetType == 'SLIDER'){
      if (curr.maxSelectSliderValue !=curr.maxSliderValue ||curr.minSelectSliderValue !=curr.minSliderValue ) {
       ++count;
      }
   }

   if (curr.multiSelect){
    curr.values.forEach(function (value) {
      if (value.selected) {
        ++count;
      }
  });
   }
    
  acc[code] = count;
    return acc;
  }, {});
  
  return countDict;
}
static fnScrollIntoActivePoduct(products) {

  if (!products) {
    return;
  }
  
  const id = localStorage.getItem('activeProductCode');
  
  if (!id) {
    return;
  }
  
  const activeProductPLP = products.filter((obj) => {
    return obj.code === id;
  });

  window.setTimeout(function() {
    
    if ( activeProductPLP && activeProductPLP.length > 0 && document.getElementById('itemBox-'+activeProductPLP[0].code) ) {
        document.getElementById('itemBox-'+activeProductPLP[0].code).scrollIntoView({behavior: 'smooth'});
    }
      
  }, 3000);
}

}
