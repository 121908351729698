import { NgModule } from '@angular/core';
import {
  AuthModule,
  ExternalRoutesModule,
  ProductModule,
  ProductOccModule,
  UserOccModule
} from '@spartacus/core';
import {
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  JsonLdBuilderModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  NavigationModule, PDFModule, ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  TabParagraphContainerModule,
  VideoModule
} from '@spartacus/storefront';
import { DoehlerCustomCategoryModule } from '../doehler-custom-category/doehler-custom-category.module';
import { DoehlerCustomPdpModule } from '../doehler-custom-pdp/doehler-custom-pdp.module';
import { DoehlerWishListOccModule } from "../doehler-custom-wishlist";
import { DoehlerWishListCoreModule } from "../doehler-custom-wishlist/core/doehler-wishlist-core.module";
import { DoehlerCustomWishlistModule } from '../doehler-custom-wishlist/doehler-custom-wishlist.module';


@NgModule({
  imports: [
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule, // will be come part of auth package
    LoginRouteModule, // will be come part of auth package
    ProductModule,
    ProductOccModule,
        // Product UI
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    UserOccModule,
    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    VideoModule,
    PDFModule,

    /************************* Opt-in features *************************/
    ExternalRoutesModule.forRoot(),
    JsonLdBuilderModule,
    DoehlerCustomPdpModule,
    DoehlerCustomCategoryModule,
    DoehlerCustomWishlistModule,
    DoehlerWishListCoreModule.forRoot(),
    DoehlerWishListOccModule
  ],
})
export class SpartacusFeaturesModule {}
