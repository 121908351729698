import { Injectable } from '@angular/core';
import {
  Converter,
  ConverterService,
  EntitiesModel
} from '@spartacus/core';
import {
  DoehlerWishListSliderListData,
  DoehlerWishListSliderListList
} from '../../core/model/wishlist.model';


@Injectable({
  providedIn: 'root',
})
export class OccDoehlerWishListsSliderListNormalizer
  implements Converter<DoehlerWishListSliderListList, EntitiesModel<DoehlerWishListSliderListData>> {
  constructor(private converter: ConverterService) {}

  convert(
    source: DoehlerWishListSliderListList,
    target?: EntitiesModel<DoehlerWishListSliderListData>
  ): EntitiesModel<DoehlerWishListSliderListData> {
    if (target === undefined) {
      target = {
        values: source.wishlistSlider,
      };
    }
    return target;
  }
}


