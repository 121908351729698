import { Component } from '@angular/core';
import { AuthService, AuthStorageService, AuthToken, UserIdService } from '@spartacus/core';
import { environment } from './../environments/environment';

declare let gigya: any;
declare global {
  interface Window {
    onGigyaServiceReady: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'spartacusstore';
  gigyaReady: Promise<void>;

  constructor(protected userIdService: UserIdService,
    protected authService:AuthService,
    protected authStorageService:AuthStorageService) {
    
              
    const nextPageUrl = localStorage.getItem('next-page-url');
    localStorage.setItem('next-page-url', nextPageUrl);

    //set callback for the CDC Web SDK loading event and resolve promise
    this.gigyaReady = new Promise((resolve, reject) => {

      window.onGigyaServiceReady = function () {

        resolve();

      };

    })

    this.gigyaReady.then(() => {

      // set active organization

      if (typeof gigya !== 'undefined') {

        gigya.accounts.b2b.getOrganizationContext({

          callback: function (res) {
            localStorage.setItem('spartacus-accountID', res.bpId);
          },

        });

      } 

    });

    var baseUrlWithHash = new URL(window.location.href.replace(/#/g, '?'));

    if (baseUrlWithHash) {
      var token = baseUrlWithHash.searchParams.get('access_token');

      if (token) {
        
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
        );
        
        var decodeToken = JSON.parse(jsonPayload);
        this.userIdService.setUserId(decodeToken.mail);
        this.userIdService.takeUserId(decodeToken.mail);
        
        let currentToken: AuthToken;
        
        this.authStorageService
        .getToken()
        .subscribe((tok) => (currentToken = tok))
        .unsubscribe(); 

        currentToken.access_token = base64;
        
        this.authStorageService.setToken(currentToken);
        
        let customerID = decodeToken.customerID;
        
        if (customerID && customerID.includes(','))
          customerID = customerID.split(",")[0];


        localStorage.setItem('spartacus-exp', decodeToken.exp);
        localStorage.setItem('spartacus-customerID', customerID);

        this.authService.checkOAuthParamsInUrl();
       

        this.authService.isUserLoggedIn().subscribe((isUserLoggedIn) => {
          
          if (isUserLoggedIn) {
            if (nextPageUrl != null)
              window.location.assign(nextPageUrl);
              else  window.location.assign(environment.baseUrl + '/my-account/wishlist');
          } else {
            this.authService.loginWithRedirect();
          }
          
        });

        
       
      }
    }

  }
}
