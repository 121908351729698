import { StateUtils } from "@spartacus/core";
import {
  DoehlerProductWishListData,
  DoehlerWishListData,
  DoehlerWishListList,
  DoehlerWishListMediaListData, DoehlerWishListSliderListData
} from "src/app/doehler-custom-wishlist/core/model/wishlist.model";


export const WISHLIST_FEATURE = 'wishlist';
export const WISHLISTMEDIALIST_FEATURE = 'wishlistmedialist';
export const WISHLISTSLIDERLIST_FEATURE = 'wishlistSlider';
export const WISHLISTPRODUCTPAGEDLISTLIST_FEATURE = 'wishlistProductPagedList';
export const DOEHLER_WISHLIST_ENTITIES = 'doehler-wishlist-entities';
export const DOEHLER_WISHLIST_MEDIALIST_ENTITIES = 'doehler-wishlist-medialist-entities';
export const DOEHLER_WISHLIST_SLIDERLIST_ENTITIES = 'doehler-wishlist-sliderlist-entities';
export const DOEHLER_WISHLIST_PRODUCTPAGEDLIST_ENTITIES = 'doehler-wishlist-productpagedlist-entities';
export const DOEHLER_WISHLIST_LIST = 'doehler-wishlist-list';
export const DOEHLER_WISHLIST_LIST_POPUP = 'doehler-wishlist-list-popup'
export const DOEHLER_WISHLIST_LIST_MEDIA_LIST = 'doehler-wishlist-list-media-list';
export const DOEHLER_WISHLIST_LIST_SLIDER_LIST = 'doehler-wishlist-list-slider-list';
export const DOEHLER_WISHLIST_PRODUCTPAGEDLIST = 'doehler-wishlist-list-product-paged-list';
export const REMOVE_WISHLIST_PROCESS_ID = 'removeWishlist';
export const ADD_WISHLIST_PROCESS_ID = 'addUserWishlist'

export interface WishListManagement
  extends StateUtils.EntityListState<DoehlerWishListData> {}

export interface WishListMediaListManagement
    extends StateUtils.EntityListState<DoehlerWishListMediaListData> {}

export interface WishListSliderListManagement
    extends StateUtils.EntityListState<DoehlerWishListSliderListData> {}

export interface WishListProductPagedListManagement
    extends StateUtils.EntityListState<DoehlerProductWishListData> {
}

export interface WishlistState {
  [WISHLIST_FEATURE]: WishListManagement;
}

export interface WishlistMediaListState {
  [WISHLISTMEDIALIST_FEATURE]: WishListMediaListManagement;
}

export interface WishlistSliderListState {
  [WISHLISTSLIDERLIST_FEATURE]: WishListSliderListManagement;
}

export interface WishlistProductPagedListState {
  [WISHLISTPRODUCTPAGEDLISTLIST_FEATURE]: WishListProductPagedListManagement;
}