import { CommonModule } from '@angular/common';
import { NgModule, OnInit } from '@angular/core';
import { UntypedFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfigModule } from '@spartacus/core';
import { environment } from "../../environments/environment";
import { PRODUCTDATA } from "../doehler-searchPage/static-data/product-data";
import { ModalModule, ModalService } from "../modal";
import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';

@NgModule({
  declarations: [WishlistPageComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        WishListComponent: {
          component: WishlistPageComponent
        },
        /*WishListCustomComponent: {
          component: WishlistPageComponent,
        },*/
        /* AddToWishListComponent: { //Default WishListComponent bu
          component: Section2aComponent,
        }, */
      },
    }),
    ModalModule,
    ReactiveFormsModule,
  ]
})
export class DoehlerCustomWishlistModule implements OnInit {

  env = environment;
  showCategories: boolean;
  showSolutions: boolean;

  headline = 'Colours & Coatings';
  productData = PRODUCTDATA;

  sortItems = [
    {
      value: 'name',
      label: 'Name'
    },
    {
      value: 'date',
      label: 'Date'
    },
    {
      value: 'size',
      label: 'Size'
    },
    {
      value: 'number',
      label: 'Product Number'
    }
  ];

  applicationFits = [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'dairy',
      label: 'Dairy & Ice Cream'
    },
    {
      value: 'confectionery',
      label: 'Confectionery'
    },
    {
      value: 'bakery',
      label: 'Bakery'
    },
    {
      value: 'non_alcoholic',
      label: 'Non-Alcoholic Beverages'
    },
    {
      value: 'alcoholic',
      label: 'Alcoholic Beverages'
    }
  ];

  colourShades = [
    {
      value: 'red',
      label: 'Red',
      code: '#CE0038'
    },
    {
      value: 'orange',
      label: 'Orange',
      code: '#FB7850'
    },
    {
      value: 'yellow',
      label: 'Yellow',
      code: '#FEE763'
    },
    {
      value: 'green',
      label: 'Green',
      code: '#5CB03C'
    },
    {
      value: 'brown',
      label: 'Brown',
      code: '#753415'
    },
    {
      value: 'white',
      label: 'White',
      code: '#FFFFFF'
    },
    {
      value: 'black',
      label: 'Black',
      code: '#000000'
    },
    {
      value: 'blue',
      label: 'Blue',
      code: '#5166A7'
    }
  ];

  regulatoryInfoRaw = [
    {
      value: 'black_carrot',
      label: 'Black Carrot'
    },
    {
      value: 'orange_carrot',
      label: 'Orange Carrot'
    },
    {
      value: 'redcarrot',
      label: 'Red Carrot'
    },
    {
      value: 'red_raddish',
      label: 'Red Raddish'
    },
    {
      value: 'purple_sweet_potatoe',
      label: 'Purple Sweet Potatoe'
    },
    {
      value: 'malt',
      label: 'Malt'
    },
    {
      value: 'safflower',
      label: 'Safflower'
    },
    {
      value: 'beta_carotene',
      label: 'Beta Carotene'
    }
  ];

  regulatoryInfoMore = [
    {
      value: 'halal',
      label: 'Halal suitable'
    },
    {
      value: 'kosher',
      label: 'Kosher suitable'
    },
    {
      value: 'eu_allergens',
      label: 'Contains EU-allergens'
    },
    {
      value: 'artificial_colour',
      label: 'Artificial Colour'
    },
    {
      value: 'colouring_concentrate',
      label: 'Colouring Concentrate'
    },
    {
      value: 'e_number',
      label: 'E-Number'
    }
  ];

  groupTabToShow = '';
  tasteTabToShow = '';

  constructor(private fb: UntypedFormBuilder, private router: Router, private modalService: ModalService) {}

  ngOnInit(): void {
    this.groupTabToShow = this.productData[0].title;
    this.tasteTabToShow = this.productData[0].contents[1].title;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  goTo(target: string) {
    console.log(target);
  }

  showGroupTab(name: string) {
    const currentGroup = this.productData.find(x => x.title === name);
    this.groupTabToShow = name;
    this.tasteTabToShow = currentGroup.contents[1].title;
  }

  showTasteTab(name: string) {
    this.tasteTabToShow = name;
  }

  toggleFilterBar() {
    const myFlyIn = document.getElementById('filterFlyIn') as HTMLElement;
    if (myFlyIn.classList.contains('visible')) {
      myFlyIn.classList.remove('visible');
    } else {
      myFlyIn.classList.add('visible');
    }
  }

  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${sImgSrcUrl})`
    };
  }

  setBackgroundColour(sColourCode: string): object {
    return {
      'background-color': `${sColourCode}`
    };
  }
}

