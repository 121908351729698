import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CmsConfig, ConfigModule, provideDefaultConfig, StateModule } from '@spartacus/core';
import { AppRoutingModule, CarouselModule, CmsPageGuard, IconModule, MediaModule, OutletModule, PageLayoutComponent, ProductCarouselModule, ProductImagesModule, ProductListItemContext, ProductListItemContextSource, PRODUCT_DETAILS_URL_MATCHER, StarRatingModule } from '@spartacus/storefront';
import { ProductFilterComponent } from "../doehler-searchPage/product-filter.component";
import { ModalModule } from '../modal';
import { CustomBottomHeaderComponent } from './custom-bottom-header/custom-bottom-header.component';
import { doehlerOccProductDetailConfig } from './doehler-occ-product-detail-config';
import { Section1Component } from './section1/section1.component';
import { Section2AComponent } from './section2-a/section2-a.component';
import { Section2BComponent } from './section2-b/section2-b.component';
import { Section3Component } from './section3/section3.component';
import { Section4aComponent } from './section4a/section4a.component';
import { Section4bComponent } from './section4b/section4b.component';
import { Section4cComponent } from './section4c/section4c.component';


@NgModule({
  declarations: [
    Section1Component,
    Section2AComponent,
    Section2BComponent,
    Section3Component,
    Section4aComponent,
    Section4bComponent,
    Section4cComponent,
    CustomBottomHeaderComponent,ProductFilterComponent],
    exports: [
      Section1Component,
      Section2AComponent,
      Section2BComponent,
      Section3Component,
      Section4aComponent,
      Section4bComponent,
      Section4cComponent,
      CustomBottomHeaderComponent,ProductFilterComponent],
    
  imports: [
    
    StoreModule.forRoot({}),
    CommonModule,
    StateModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  
    RouterModule.forChild([
      {
          // path with a dynamic parameter:
          path: 'product/:productCode',

          // page label without a parameter, starting with slash:
          data: { pageLabel: '/product' },

          // the following are needed to display slots and components from the CMS:
          component: PageLayoutComponent,
          canActivate: [CmsPageGuard]
      }
  ]),
    CommonModule,
    StarRatingModule,
    IconModule,
    ProductImagesModule,
    MediaModule,
    OutletModule,
    CarouselModule,
    ModalModule,//PDP Send Request butonunun POPUP icerigi icin
    FormsModule,//PDP Send Request butonunun POPUP icerigi icin
    ReactiveFormsModule,//PDP Send Request butonunun POPUP icerigi icin
    HttpClientModule,
    ProductCarouselModule,
    ConfigModule.withConfig({
      cmsComponents: {
        DoehlerProductDetails2AFlexComponent: {
          component: Section2AComponent,
        },
        DoehlerProductDetails2BFlexComponent: {
          component: Section2BComponent,
        },
        DoehlerProductDetails3FlexComponent: {
          component: Section3Component,
        },
        DoehlerProductDetails4AFlexComponent: {
          component: Section4aComponent,
        },
        DoehlerProductDetails4BFlexComponent: {
          component: Section4bComponent,
        },
        DoehlerProductDetails4CFlexComponent: {
          component: Section4cComponent,
        },
        BreadcrumbComponent:{
          component: CustomBottomHeaderComponent,
        },
        DoehlerTestSearchPageDetails1FlexComponent: {
          component: ProductFilterComponent,
        },
      },
    } as CmsConfig),
    FontAwesomeModule,
  ],


  providers: [
    provideDefaultConfig(doehlerOccProductDetailConfig),
      ProductListItemContextSource,
      { provide: ProductListItemContext, useExisting: ProductListItemContextSource },
      provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        DoehlerProductDetails2AFlexComponent: {
          component: Section2AComponent,
        },
        DoehlerProductDetails2BFlexComponent: {
          component: Section2BComponent,
        },
        DoehlerProductDetails3FlexComponent: {
          component: Section3Component,
        },
        DoehlerProductDetails4AFlexComponent: {
          component: Section4aComponent,
        },
        DoehlerProductDetails4BFlexComponent: {
          component: Section4bComponent,
        },
        DoehlerProductDetails4CFlexComponent: {
          component: Section4cComponent,
        },
        BreadcrumbComponent:{
          component: CustomBottomHeaderComponent,
        },
        DoehlerTestSearchPageDetails1FlexComponent: {
          component: ProductFilterComponent,
        },
      },
     
        routing: {
          routes: {
            product: {
              matchers: [PRODUCT_DETAILS_URL_MATCHER],
            },
          },
        },
    }),
  ],

})
export class DoehlerCustomPdpModule { }
function reducerToken(PRODUCT_FEATURE: string, reducerToken: any, arg2: { metaReducers: any; }): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
  throw new Error('Function not implemented.');
}

function defaultOccConfiguratorProductConfig(defaultOccConfiguratorProductConfig: any): import("@angular/core").Provider {
  throw new Error('Function not implemented.');
}

