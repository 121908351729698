import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faAngleDoubleRight, faArrowLeft, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthConfig, CmsProductCarouselComponent, EntitiesModel, OccConfig, OccEndpointsService, Product, UserIdService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DoehlerWishListSearchConfig } from 'src/app/app.module';
import { DoehlerProduct } from 'src/app/doehler-custom-category/custom-category-list/doehler-product-search.model';
import { DoehlerWishListData, DoehlerWishListService } from 'src/app/doehler-custom-wishlist';
import { ModalService } from 'src/app/modal/modal.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'doehler-section2-a',
  templateUrl: './section2-a.component.html',
  styleUrls: ['./section2-a.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Section2AComponent implements OnInit, AfterViewInit {
    
  iconTypes = ICON_TYPE;
  faFilter = faFilter;
  faAngleDoubleRight = faAngleDoubleRight;
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  saveToCollection: UntypedFormGroup;
  createCollectionForm: UntypedFormGroup;
  removeFromCollection: UntypedFormGroup;
  isChecked:boolean;
  protected PAGE_SIZE = 100;
  wishlistParams: DoehlerWishListSearchConfig = { sort: 'code' };
  doehlerWishListList$: Observable<EntitiesModel<DoehlerWishListData>>;
  product$: Observable<DoehlerProduct> = this.currentProductService.getProduct();
  env = environment;
  baseUrlMachine = '';
  isFavourite: boolean;
    //html icerisinde similir productların resimlerini göstermek icin kullanildi
    getCustomVariantThumbnailUrl(thumbnailUrl: string): string {
        return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
    }
    //9002 portundaki bir resmi almak icin
    getFeaturesDataValueIconUrl(sImgSrcUrl: string): object {
        return {
            'background-image': `url(${sImgSrcUrl})`,
        };
    }
    getHomePage(){
        return this.localtion.back();
    }
    setBackgroundIcon(sImgSrcUrl: string): object {
        return {
            'background-image': `url(/assets/icons/${sImgSrcUrl})`
        };
    }
    setBackgroundImage(sImgSrcUrl: string): object {
        return {
            'background-image': `url(${sImgSrcUrl})`
        };
    }
    goTo(target: string) {
        //console.log(target);
    }
    constructor(
        protected component: CmsComponentData<CmsProductCarouselComponent>,
        
        private currentProductService: CurrentProductService,private config: OccConfig,
                private fb: UntypedFormBuilder, private router: Router,private modalService: ModalService,
                protected http: HttpClient,
                protected occEndpoints: OccEndpointsService,
                protected authConfig: AuthConfig,
                protected userIdService: UserIdService,
                protected doehlerWishListService: DoehlerWishListService,
                private localtion: Location) {}
                data$: Observable<CmsProductCarouselComponent> = this.component.data$;
    ngOnInit(): void {
        this.product$.pipe(take(1)).subscribe(obj => {
            localStorage.setItem('activeProductCode', obj.code);
        })
       
        this.baseUrlMachine = this.env.baseUrl
        this.product$.pipe(take(1)).subscribe(rfs => {
            this.isFavourite = rfs.isFavourite;
        });
        this.removeFromCollection = this.fb.group({
            userId: [localStorage.getItem('userId')],
        });
        this.saveToCollection = this.fb.group({
            userId: [localStorage.getItem('userId')],
        });
        this.createCollectionForm = this.fb.group({
            userId: [localStorage.getItem('userId')],
        });
        this.userIdService.getUserId().pipe(take(1)).subscribe((userId) => {
            try{
                if (localStorage.getItem("spartacus⚿⚿auth").includes('userId')) {
                    localStorage.setItem('userId', userId)
                }
            } catch (ex) {
                console.log("Error "+ex);
            }
        })
        let accountExternalId = localStorage.getItem("spartacus-accountID");
        let contactExternalId: string;
        if(accountExternalId) {
            if(localStorage.getItem("spartacus-customerID").includes(",")){
                contactExternalId = localStorage.getItem("spartacus-customerID").split(",")[0];
            }else{
                contactExternalId = localStorage.getItem("spartacus-customerID");
            }
        }
        let productCode =[];
        let productName= [];
        this.product$.pipe(take(1)).subscribe(product => {
            productCode[0] = product.code;
            productName[0] = product.name;
        });
    }
    ngAfterViewInit(): void {
        if (this.baseUrlMachine.includes('test')) {
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-test.doehler.com/');
        } else if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-dev.doehler.com/');
        } else {
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal.doehler.com/');
        }
    }
    filterItems(list){
        return list.Array //Array is an array in item
    }
    // sayfa ilk acıldıgında popup acık gelmesin diye butona tıklandıgında productModel olarak product setlendi
    productModel$:  Observable<Product>;
    sendMessageParentIframe(window, message, url) {
        if ('parentIFrame' in window) {
            const myParentIFrame: any = window["parentIFrame"];
            myParentIFrame.sendMessage(message, url);
        }
    }
    closeModal(id: string) {
        if (this.baseUrlMachine.includes('test')) {
            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
        } else if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
        } else {
            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
        }
        this.modalService.close(id);
    }
    openRequestASampleCommand(articlenumber: string, title: string) {
        if(this.baseUrlMachine.includes('test')) {
           this.sendMessageParentIframe(window, '{ "inquiry": "openRequestASample", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', 'https://d-portal-test.doehler.com/');
        }
        if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openRequestASample", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal-dev.doehler.com");
        }
        if(!this.baseUrlMachine.includes('test') && !this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openRequestASample", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal.doehler.com");
        }
    }
    openRequestAQuoteCommand(articlenumber: string, title: string) {
        if(this.baseUrlMachine.includes('test')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openRequestAQuote", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', 'https://d-portal-test.doehler.com/');
        }
        if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openRequestAQuote", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal-dev.doehler.com");
        }
        if(!this.baseUrlMachine.includes('test') && !this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openRequestAQuote", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal.doehler.com");
        }
    }
    openFindOutMoreCommand(articlenumber: string, title: string) {
        if(this.baseUrlMachine.includes('test')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openFindOutMore", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal-test.doehler.com");
        }
        if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openFindOutMore", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal-dev.doehler.com");
        }
        if(!this.baseUrlMachine.includes('test') && !this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{ "inquiry": "openFindOutMore", "articlenumber": "'+articlenumber+'", "title": "'+title+'"}', "https://d-portal.doehler.com");
        }
    }
    favouriteProductCode;
    favouriteProductName;
    changeFavourite(code: string, name: string) {
        this.favouriteProductCode=code;
        this.favouriteProductName=name;
        if ( document.getElementById('addToFav').classList.contains('fav')) {
            this.fetchDoehlerWishListPopupPage({});
            if (this.baseUrlMachine.includes('test')) {
                this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
                this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
            } else if(this.baseUrlMachine.includes('dev')) {
                this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
                this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
            } else {
                this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
                this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
            }
            this.modalService.open("addToFavorite");
        } else{
            this.openRemoveFavoriteConfirmationModal("removeFromFavorite");
        }
    }

    openRemoveFavoriteConfirmationModal(id:string) {
        this.modalService.open(id);
      }
      removeFavorite(id:string) {
        if (this.baseUrlMachine.includes('test')) {
            this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
        } else if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
        } else {
            this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
        }
  
        const form: HTMLFormElement = document.querySelector('#removeFromWishlistForm');
        const formData = new FormData(form);
        const wishListCode = formData.get('wishlistCodePost') as string;
        this.removeFromCollection = this.fb.group({
            wishlistCode: [wishListCode],
            wishlistName: [],
            productCode: [this.favouriteProductCode],
            productName: [this.favouriteProductName],
            site: ["dCatalog"],
            loginCustomerUserId: [localStorage.getItem('userId')],
            companyId: [localStorage.getItem('spartacus-accountID')]
        });
        const toAdd = JSON.stringify(this.removeFromCollection.value);
        //post yapılacak occ servisimin urli
        var removeJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/removeProductWishList`;
        //servis post edilirken kullanılacak header
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        document.getElementById('removeFromFavorite').style.display = "none";
        //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
        let promise = new Promise<void>((resolve, reject) => {
            this.http.post(removeJsonWishListURL, toAdd, httpOptions)
                .toPromise()
                .then(
                    response => { // Success
                        resolve();
                        if ( document.getElementById('addToFav') != null ){
                            document.getElementById('addToFav').classList.remove('addedToFavorite');
                            document.getElementById('addToFav').classList.add('fav');
                        }
                        if (this.baseUrlMachine.includes('test')) {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                        } else if(this.baseUrlMachine.includes('dev')) {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                        } else {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                        }
                    
                    },
                    msg => { // Error
                        if (this.baseUrlMachine.includes('test')) {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                        } else if(this.baseUrlMachine.includes('dev')) {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                        } else {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                        }
                        reject(msg);
                    }
                )
        });
       
        this.modalService.open(id);
    }
    favoriteSubmitForm() {
        const form: HTMLFormElement = document.querySelector('#saveWishlistForm');
        const formData = new FormData(form);
        const code = formData.get('wishlistCodePost') as string;
        this.saveToCollection = this.fb.group({
            wishlistCode: [code],
            wishlistName: [],
            productCode: [this.favouriteProductCode],
            productName: [this.favouriteProductName],
            site: ["dCatalog"],
            loginCustomerUserId: [localStorage.getItem('userId')],
            companyId: [localStorage.getItem('spartacus-accountID')]
        });
        document.getElementById('addToFavorite').style.display = "none";
        document.getElementById('requestToAddFavoriteList').style.display = "none";
        document.getElementById('requestCreateFavorite').style.display = "none";
        if (document.getElementById('addToFavorite').style.display = "none") {
            document.getElementById('requestToAddFavoriteList').style.display = "block";
        } else if (document.getElementById('requestCreateFavorite').style.display = "none") {
            document.getElementById('requestToAddFavoriteList').style.display = "block";
        }
        const toAdd = JSON.stringify(this.saveToCollection.value);
        //post yapılacak occ servisimin urli
        var addJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/addJsonWishList`;
        //servis post edilirken kullanılacak header
        var httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        };
        //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
          let promise = new Promise<void>((resolve, reject) => {
              this.http.post(addJsonWishListURL, toAdd, httpOptions)
                  .toPromise()
                  .then(
                      response => { // Success
                          resolve();
                          if ( document.getElementById('addToFav') != null ) {
                              document.getElementById('addToFav').classList.add('addedToFavorite')
                              document.getElementById('addToFav').classList.remove('fav');
                          }
                          //let msg = "Message : " + response.msg + " \n WishListPK :" + response.wishlistCode + " \n WishList NAME : " + response.wishlistName + " \n Added Product Code : " + response.productCode ;
                          this.isFavourite = true;
                          if (this.baseUrlMachine.includes('test')) {
                              this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                          } else if(this.baseUrlMachine.includes('dev')) {
                              this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                          } else {
                              this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                          }
                          setTimeout (function() {
                              document.getElementById('requestToAddFavoriteList').style.display = "none";
                          },500)
                          this.saveToCollection.reset();
                      },
                      msg => { // Error
                          this.isFavourite = false;
                          if (this.baseUrlMachine.includes('test')) {
                              this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                          } else if(this.baseUrlMachine.includes('dev')) {
                              this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                          } else {
                              this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                          }
                          reject(msg);
                      }
                  )
          });
    }
    submitCreateForm(productCode1, productName) {
      const form: HTMLFormElement = document.querySelector('#createWishlistForm');
      const formData = new FormData(form);
      const text = formData.get('wishlistNamePost') as string;
      this.createCollectionForm = this.fb.group({
            wishlistCode: [],
            wishlistName: [text],
            productCode: [productCode1],
            productName: [productName],
            site: ["dCatalog"],
            loginCustomerUserId: [localStorage.getItem('userId')],
            companyId: [localStorage.getItem('spartacus-accountID')]
        });

      const toAdd = JSON.stringify(this.createCollectionForm.value);
      //post yapılacak occ servisimin urli
      var createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/createWishList`;

      //servis post edilirken kullanılacak header
      var httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
      let promise = new Promise<void>((resolve, reject) => {
            this.http.post(createJsonWishListURL, toAdd, httpOptions)
                .toPromise()
                .then(
                    response => { // Success
                        resolve();
                        form.reset();
                        if(response==="Cannot create name with same name") {
                            document.getElementById('createErrorDesktop').classList.add('active');
                            document.getElementById('createErrorMobile').classList.add('active');
                        }else {
                            if ( document.getElementById('addToFav') != null ) {
                                document.getElementById('addToFav').classList.add('addedToFavorite')
                                document.getElementById('addToFav').classList.remove('fav');
                            }
                            document.getElementById('createErrorDesktop').classList.remove('active');
                            document.getElementById('createErrorMobile').classList.remove('active');
                            document.getElementById('requestCreateFavorite').style.display = "none";
                            document.getElementById('requestToAddFavoriteList').style.display = "none";
                            if (document.getElementById('requestCreateFavorite').style.display = "none") {
                                document.getElementById('requestToAddFavoriteList').style.display = "block";
                            }
                            if (this.baseUrlMachine.includes('test')) {
                                this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                            } else if(this.baseUrlMachine.includes('dev')) {
                                this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                            } else {
                                this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                            }
                            setTimeout (function() {
                                document.getElementById('requestToAddFavoriteList').style.display = "none";
                            },500)
                        }
                    },
                    msg => { // Error
                      this.isFavourite = false;
                        if (this.baseUrlMachine.includes('test')) {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                        } else if(this.baseUrlMachine.includes('dev')) {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                        } else {
                            this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                        }
                        reject(msg);
                    }
                )
        });
    }
    createFavorite(id: string) {
        if (this.baseUrlMachine.includes('test')) {
            this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
        } else if(this.baseUrlMachine.includes('dev')) {
            this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
        } else {
            this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
            this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
        }
        if(document.getElementById('createErrorDesktop').classList.contains('active')) {
            document.getElementById('createErrorDesktop').classList.remove('active');
        }
        if (document.getElementById('createErrorMobile').classList.contains('active')) {
            document.getElementById('createErrorMobile').classList.remove('active');
        }
        document.getElementById('addToFavorite').style.display = "none";
        document.getElementById('requestCreateFavorite').style.display = "none";
        if(document.getElementById('addToFavorite').style.display = "none") {
            document.getElementById('requestCreateFavorite').style.display = "block";
            this.modalService.open(id);
        }
    }

    protected fetchDoehlerWishListPopupPage(searchConfig: DoehlerWishListSearchConfig): void {
        searchConfig.customerUserId = localStorage.getItem('userId');
        searchConfig.pageSize = this.PAGE_SIZE;
        searchConfig.companyId = localStorage.getItem('spartacus-accountID');
        if (this.wishlistParams.customerUserId == null) {
            this.wishlistParams.customerUserId = localStorage.getItem('userId');
        }
        if (this.wishlistParams.companyId == null) {
            this.wishlistParams.companyId = localStorage.getItem('spartacus-accountID');
        }
        this.doehlerWishListList$ = this.doehlerWishListService.getPopupList(this.wishlistParams);
    }
}

