import { DoehlerOccConfig } from "src/app/occ/occ-models/doehler-occ-config";

export const doehlerWishlistOccConfig: DoehlerOccConfig = {

  doehlerBackend: {
    occ: {
      endpoints: {
        wishlist: '/wishList/list?customerUserId=${customerUserId}',
        wishlistpopup: '/wishList/list-popup?customerUserId=${customerUserId}',
        wishlistmedialist: '/wishList/wishlistmedialist?customerUserId=${customerUserId}',
        wishlistSlider: '/wishList/slider-list?customerUserId=${customerUserId}',
        wishlistProductPagedList: '/wishList/paged-list?customerUserId=${customerUserId}'
      },
    },
  },

};
