import { Component, OnInit } from '@angular/core';
import { CmsProductReferencesComponent, Product, ProductReference, ProductReferenceService, OccConfig } from '@spartacus/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'doehler-section2-b',
  templateUrl: './section2-b.component.html',
  styleUrls: ['./section2-b.component.scss'],
})
export class Section2BComponent implements OnInit {

  product$: Observable<Product> = this.current.getProduct();
  
  title$ = this.component.data$.pipe(map((d) => d?.title));

  private currentProductCode$: Observable<string> = this.current.getProduct().pipe(
    filter(Boolean),
    map((p: Product) => p.code),
    distinctUntilChanged(),
    tap(() => this.referenceService.cleanReferences())
  );

  /**
   * Obervable with an Array of Observables. This is done, so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   */
  items$: Observable<Observable<Product>[]> = combineLatest([this.currentProductCode$, this.component.data$]).pipe(
    switchMap(([code, data]) => this.getProductReferences(code, data?.productReferenceTypes))
  );

  
  constructor(
    protected component: CmsComponentData<CmsProductReferencesComponent>,
    protected current: CurrentProductService,
    protected referenceService: ProductReferenceService,
    private config: OccConfig
      ) {}

  private getProductReferences(code: string, referenceType: string): Observable<Observable<Product>[]> {

    this.referenceService.loadProductReferences(code, "OTHERS");
    return this.referenceService.getProductReferences(code, "OTHERS").pipe(
      filter(Boolean),
      map((refs: ProductReference[]) => refs.map((ref) => of(ref.target)))
    );
  }

  //PDP /p/productcode/ olarak acilabilir
  getCustomSimilarProductUrl(
    similarProductCode: string,
    similarProductName: string,
  ): string {
    let encodeName = encodeURIComponent(similarProductName);
    return  /p/+`${similarProductCode}`;
  }

  //html icerisinde similir productların resimlerini göstermek icin kullanildi
  getCustomVariantThumbnailUrl(thumbnailUrl: string) : object{
    console.log(`${this.config.backend.occ.baseUrl}${thumbnailUrl}`);
    //return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
    return {
      'background-image': `${this.config.backend.occ.baseUrl}${thumbnailUrl}`,
    };
  }

  //html icerisinde similir productların resimlerini göstermek icin kullanildi
  getFeaturesDataValueUrl(thumbnailUrl: string): string {
    return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
  }

  //9002 portundaki bir resmi almak icin
  getFeaturesDataValueIconUrl(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${this.config.backend.occ.baseUrl}${sImgSrcUrl})`,
    };
  }

  setBackgroundIcon(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/icons/${sImgSrcUrl})`,
    };
  }

  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/images/${sImgSrcUrl})`,
    };
  }

  ngOnInit(): void {

    /*  this.product$.subscribe(res => {
      console.log(res.productReferences[0]);

      for(let i=0;i<res.productReferences.length;i++){
        console.log(res.productReferences[i].target.name);
        console.log(res.productReferences[i].target.code);
      } 
   }); */

  }

}
