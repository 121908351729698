import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {effects, effectsMedia, effectsSlider} from './effects/index';
import {WISHLIST_FEATURE, WISHLISTMEDIALIST_FEATURE, WISHLISTSLIDERLIST_FEATURE, WISHLISTPRODUCTPAGEDLISTLIST_FEATURE} from './wishlist-state';
import {
  metaReducers,
  reducerMediaListProvider,
  reducerMediaListToken,
  reducerSliderListProvider,
  reducerSliderListToken,
  reducerProductPagedListProvider,
  reducerProductPagedListToken,
  reducerProvider,
  reducerToken
} from './reducers/index';

@NgModule({
  imports: [
    StoreModule.forFeature(WISHLIST_FEATURE, reducerToken, {
      metaReducers,
    }),
    EffectsModule.forFeature(effects),
    StoreModule.forFeature(WISHLISTMEDIALIST_FEATURE, reducerMediaListToken, {
      metaReducers,
    }),
    StoreModule.forFeature(WISHLISTSLIDERLIST_FEATURE, reducerSliderListToken, {
      metaReducers,
    }),
    StoreModule.forFeature(WISHLISTPRODUCTPAGEDLISTLIST_FEATURE, reducerProductPagedListToken, {
      metaReducers,
    }),
  ],
  providers: [reducerProvider,reducerMediaListProvider, reducerSliderListProvider, reducerProductPagedListProvider],
})
export class DoehlerWishListStoreModule {}