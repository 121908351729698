import { ModuleWithProviders, NgModule } from '@angular/core';
import { WhishlistConnector } from './connectors/wishlist.connector';
import { DoehlerWishListStoreModule } from './store/wishlist-store.module';

@NgModule({
  imports: [DoehlerWishListStoreModule],
})
export class DoehlerWishListCoreModule {
  static forRoot(): ModuleWithProviders<DoehlerWishListCoreModule> {
    return {
      ngModule: DoehlerWishListCoreModule,
      providers: [WhishlistConnector],
    };
  }
}
