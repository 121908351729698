import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { EntitiesModel, SearchConfig, StateUtils } from "@spartacus/core";
import {DoehlerProductWishListData, DoehlerWishListData, DoehlerWishListList, DoehlerWishListMediaListData, DoehlerWishListSliderListData} from "../../model/wishlist.model";
import {
  WishListManagement,
  WishlistState,
  WISHLIST_FEATURE,
  WishlistMediaListState,
  WishListMediaListManagement, WISHLISTMEDIALIST_FEATURE,
  WishlistSliderListState,
  WishListSliderListManagement, WISHLISTSLIDERLIST_FEATURE, WishlistProductPagedListState, WishListProductPagedListManagement, WISHLISTPRODUCTPAGEDLISTLIST_FEATURE
} from "../wishlist-state";



export const getWishlistState: MemoizedSelector<
WishlistState,
WishlistState
> = createFeatureSelector<WishlistState>(WISHLIST_FEATURE);

export const getWishlistMediaListState: MemoizedSelector<
    WishlistMediaListState,
    WishlistMediaListState
    > = createFeatureSelector<WishlistMediaListState>(WISHLISTMEDIALIST_FEATURE);

export const getWishlistSliderListState: MemoizedSelector<
    WishlistSliderListState,
    WishlistSliderListState
    > = createFeatureSelector<WishlistSliderListState>(WISHLISTSLIDERLIST_FEATURE);

export const getWishlistProductPagedListState: MemoizedSelector<
    WishlistProductPagedListState,
    WishlistProductPagedListState
    > = createFeatureSelector<WishlistProductPagedListState>(WISHLISTPRODUCTPAGEDLISTLIST_FEATURE);


export const getWishlistManagmentState: MemoizedSelector<
WishlistState,
WishListManagement
> = createSelector(
  getWishlistState,
  (state: WishlistState) => state[WISHLIST_FEATURE]
);

export const getWishlistMediaListManagmentState: MemoizedSelector<
    WishlistMediaListState,
    WishListMediaListManagement
    > = createSelector(
    getWishlistMediaListState,
    (state: WishlistMediaListState) => state[WISHLISTMEDIALIST_FEATURE]
);

export const getWishlistSliderListManagmentState: MemoizedSelector<
    WishlistSliderListState,
    WishListSliderListManagement
    > = createSelector(
    getWishlistSliderListState,
    (state: WishlistSliderListState) => state[WISHLISTSLIDERLIST_FEATURE]
);

export const getWishlistProductPagedListManagmentState: MemoizedSelector<
    WishlistProductPagedListState,
    WishListProductPagedListManagement
    > = createSelector(
    getWishlistProductPagedListState,
    (state: WishlistProductPagedListState) => state[WISHLISTPRODUCTPAGEDLISTLIST_FEATURE]
);

export const getWishListsState: MemoizedSelector<
WishlistState,
  StateUtils.EntityLoaderState<DoehlerWishListData>
> = createSelector(
  getWishlistManagmentState,
  (state: WishListManagement) => state && state.entities
);

export const getWhishList = (
  pk: string
): MemoizedSelector<
WishlistState,
  StateUtils.LoaderState<DoehlerWishListData>
> =>
  createSelector(
    getWishListsState,
    (state: StateUtils.EntityLoaderState<DoehlerWishListData>) =>
      StateUtils.entityLoaderStateSelector(state, pk)
  );


export const getWhishListList = (
  params: SearchConfig
): MemoizedSelector<
  WishlistState,
  StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>
> =>
  createSelector(
    getWishlistManagmentState,
    (state: WishListManagement) =>
      StateUtils.denormalizeSearch<DoehlerWishListData>(state, params)
  );

  export const getWhishListPopup = (
  params: SearchConfig
): MemoizedSelector<
  WishlistState,
  StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>
> =>
  createSelector(
    getWishlistManagmentState,
    (state: WishListManagement) =>
      StateUtils.denormalizeSearch<DoehlerWishListData>(state, params)
  );


export const getWhishListListMediaList = (
    params: SearchConfig
): MemoizedSelector<
    WishlistMediaListState,
    StateUtils.LoaderState<EntitiesModel<DoehlerWishListMediaListData>>
    > =>
    createSelector(
        getWishlistMediaListManagmentState,
        (state: WishListMediaListManagement) =>
            StateUtils.denormalizeSearch<DoehlerWishListMediaListData>(state, params)
    );

export const getWhishListListSliderList = (
    params: SearchConfig
): MemoizedSelector<WishlistSliderListState,
    StateUtils.LoaderState<EntitiesModel<DoehlerWishListSliderListData>>> =>
    createSelector(
        getWishlistSliderListManagmentState,
        (state: WishListSliderListManagement) =>
            StateUtils.denormalizeSearch<DoehlerWishListSliderListData>(state, params)
    );

export const getWhishListListProductPagedList = (
    params: SearchConfig
): MemoizedSelector<WishlistProductPagedListState,
    StateUtils.LoaderState<EntitiesModel<DoehlerProductWishListData>>> =>
    createSelector(
        getWishlistProductPagedListManagmentState,
        (state: WishListProductPagedListManagement) =>
            StateUtils.denormalizeSearch<DoehlerProductWishListData>(state, params)
    );