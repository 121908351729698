const PRODUCTDATA = [
  {
    title: 'colours',
    contents: [
      {
        title: 'all',
        products: [] // dynamically fill prodcucts. Concat all others...
      },
      {
        title: 'red',
        products: [
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/red-colour-example.jpg',
            title: 'Colouring Concentrate Red Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'orange',
        products: [
          {
            productImage: '/assets/images/product/orange-colour-example.jpg',
            title: 'Colouring Concentrate Oranage Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/orange-colour-example.jpg',
            title: 'Colouring Concentrate Oranage Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/orange-colour-example.jpg',
            title: 'Colouring Concentrate Oranage Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/orange-colour-example.jpg',
            title: 'Colouring Concentrate Oranage Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/orange-colour-example.jpg',
            title: 'Colouring Concentrate Oranage Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'yellow',
        products: [
          {
            productImage: '/assets/images/product/yellow-colour-example.jpg',
            title: 'Colouring Concentrate Yellow Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/yellow-colour-example.jpg',
            title: 'Colouring Concentrate Yellow Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/yellow-colour-example.jpg',
            title: 'Colouring Concentrate Yellow Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/yellow-colour-example.jpg',
            title: 'Colouring Concentrate Yellow Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/yellow-colour-example.jpg',
            title: 'Colouring Concentrate Yellow Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/yellow-colour-example.jpg',
            title: 'Colouring Concentrate Yellow Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'green',
        products: [
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/green-colour-example.jpg',
            title: 'Colouring Concentrate Green Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'brown',
        products: [
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/brown-colour-example.jpg',
            title: 'Colouring Concentrate Brown Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'white',
        products: [
          {
            productImage: '/assets/images/product/white-colour-example.jpg',
            title: 'Colouring Concentrate White Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/white-colour-example.jpg',
            title: 'Colouring Concentrate White Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/white-colour-example.jpg',
            title: 'Colouring Concentrate White Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'black',
        products: [
          {
            productImage: '/assets/images/product/black-colour-example.jpg',
            title: 'Colouring Concentrate Black Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/black-colour-example.jpg',
            title: 'Colouring Concentrate Black Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/black-colour-example.jpg',
            title: 'Colouring Concentrate Black Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/black-colour-example.jpg',
            title: 'Colouring Concentrate Black Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/black-colour-example.jpg',
            title: 'Colouring Concentrate Black Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'blue',
        products: [
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/blue-colour-example.jpg',
            title: 'Colouring Concentrate Blue Clear Lq',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'coatings',
    contents: [
      {
        title: 'all',
        products: [] // dynamically fill prodcucts. Concat all others...
      },
      {
        title: 'polishing agent',
        products: [
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #1',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #2',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #3',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #4',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #5',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'CPolishing Agent #6',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #7',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/polishing-agent.png',
            title: 'Polishing Agent #8',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'glazing agent',
        products: [
          {
            productImage: '/assets/images/product/glazing-agent.jpg',
            title: 'Glazing Agent #1',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/glazing-agent.jpg',
            title: 'Glazing Agent #2',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/glazing-agent.jpg',
            title: 'Glazing Agent #3',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/glazing-agent.jpg',
            title: 'Glazing Agent #4',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/glazing-agent.jpg',
            title: 'Glazing Agent #5',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/glazing-agent.jpg',
            title: 'Glazing Agent #6',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      },
      {
        title: 'anti-sticking agent',
        products: [
          {
            productImage: '/assets/images/product/anti-sticking-agent.jpg',
            title: 'Anti Sticking Agent #1',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/anti-sticking-agent.jpg',
            title: 'Anti Sticking Agent #2',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/anti-sticking-agent.jpg',
            title: 'Anti Sticking Agent #3',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/anti-sticking-agent.jpg',
            title: 'Anti Sticking Agent #4',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          },
          {
            productImage: '/assets/images/product/anti-sticking-agent.jpg',
            title: 'Anti Sticking Agent #5',
            productNumber: '746142',
            mainAttributes: [
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Unit (E1%, 1cm)' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Colour Content 1' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Cloudiness' },
              { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Carotene Content' }
            ]
          }
        ]
      }
    ]
  }
];

export { PRODUCTDATA };
