<!--<div class="container-fluid">-->
    <div class="content-wrapper">
        <div class="inner">
            <p class="disclaimerHeadline">Disclaimer</p>
            <p>This product specification describes the composition and properties of our product to the best of our knowledge. It replaces all previous versions and is valid without signature. Due to raw material variations deviations from this specification may occur within good manufacturing practice in order to standardize our product. This product and its ingredients conform to German or European food law requirements. The permissibility of our product may be limited to certain applications. The responsibility for the microbiological stability of the foodstuff made with our products lies with the user. It's also the duty of the user to insure that the use of our product and the placing on the market of the food made with it complies with local applicable legal requirements, taking also into consideration specific needs, e.g. those of children.
            </p>
        </div>
    </div>
<!--
</div>-->
