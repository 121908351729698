import { EntitiesModel, SearchConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import {DoehlerProductWishListData, DoehlerWishListData, DoehlerWishListMediaListData, DoehlerWishListSliderListData} from '../model';

export abstract class DoehlerWishlistAdapter {
  /**
   * Abstract method used to load product's details data.
   * Product's data can be loaded from alternative sources, as long as the structure
   * converts to the `Product`.
   *
   * @param userid The `userid` for given wishlish
   * @param scope The wishlish scope to load
   */
  abstract load(userid: string,pk:string): Observable<DoehlerWishListData>;

  abstract loadList(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>>;

  abstract loadPopupList(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>>;

  abstract loadListMediaList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListMediaListData>>;

  abstract loadListSliderList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListSliderListData>>;

  abstract loadProductPagedList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerProductWishListData>>;

}
