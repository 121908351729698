<div class="content-wrapper">
    <div class="inner">
        <h3 class="withDividerLine">Specifications</h3>
        <div class="specifications-section">
            <ng-container *ngFor="let specification of products.specifications">
                <div class="accordionItem" [ngClass]="{ active: specification.entity == tabToShow }">
                    <div class="toggle"
                      (click)="showTab(specification.entity)">
                {{ specification.entity }}
                    </div>
                  <div class="accordionContent overview" *ngIf="(tabToShow != 'Regulatory Info' && specification.entity != 'Regulatory Info') && (tabToShow != 'Physical & Chemical Characteristics' && specification.entity != 'Physical & Chemical Characteristics') && (tabToShow != 'Application Fit' && specification.entity != 'Application Fit')">
                      <ng-container *ngFor="let specification of products.specifications">
                      <div class="content-area" *ngIf="tabToShow == specification.entity">
                      <div class="dataRow">
                        <ng-container *ngIf="tabToShow != 'Overview' && specification.entity != 'Overview';else other_contentOverview">
                            <div class="dataColumn" *ngFor="let entry of specification.information">
                              <div class="attribute">{{ entry.attribute }}</div>
                              <div class="value">{{ entry.value }}</div>
                            </div>
                          </ng-container>
                      </div>
                    </div>
                      </ng-container>
                    <ng-template #other_contentOverview>
                      <ng-container *ngIf="product$ | async as productl">
                          <ng-container *ngIf="productl.doehlerCatalogConfigData != undefined">
                              <ng-container *ngFor="let specificationsOverviewDCatalog of  productl.doehlerCatalogConfigData.productDetailSpecificationsOverviewDCatalog">
                                  <ng-container  *ngFor="let featureValues of specificationsOverviewDCatalog.featureData.featureValues">
                                      <div class="dataColumn">
                                          <div class="attribute">
                                              {{specificationsOverviewDCatalog.featureData.name}}
                                          </div>
                                          <div class="value">
                                              <ng-container *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                  <span>{{featureValues.value.replace('to', '&mdash;').replace('from', '')}}</span>
                                              </ng-container>

                                              <ng-container *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                  <span>{{featureValues.value}}</span>
                                              </ng-container>
                                          </div>
                                      </div>
                                  </ng-container>
                              </ng-container>
                          </ng-container>
                      </ng-container>
                    </ng-template>
                  </div>
                  <div class="accordionContent regulatory-info" *ngIf="(tabToShow != 'Overview' && specification.entity != 'Overview') && (tabToShow != 'Physical & Chemical Characteristics' && specification.entity != 'Physical & Chemical Characteristics') && (tabToShow != 'Application Fit' && specification.entity != 'Application Fit')">
                      <ng-container *ngFor="let specification of products.specifications">
                      <div class="content-area" *ngIf="tabToShow == specification.entity">
                        <div class="dataRow">
                          <ng-container *ngIf="tabToShow != 'Regulatory Info' && specification.entity != 'Regulatory Info';else other_content">
                              <div class="dataColumn" *ngFor="let entry of specification.information">
                                <div class="attribute">{{ entry.attribute }}</div>
                                <div class="value">{{ entry.value }}</div>
                              </div>
                            </ng-container>
                        </div>
                      </div>
                      </ng-container>
                        <ng-template #other_content>
                          <ng-container *ngIf="product$ | async as productl">
                              <ng-container *ngIf="productl.doehlerCatalogConfigData != undefined">
                                  <ng-container *ngFor="let specificationsOverviewDCatalog of  productl.doehlerCatalogConfigData.productDetailSpecificationsRegulatoryInfoDCatalog">
                                      <ng-container  *ngFor="let featureValues of specificationsOverviewDCatalog.featureData.featureValues">
                                          <div class="dataColumn">
                                              <div class="attribute">
                                                  {{specificationsOverviewDCatalog.featureData.name}}
                                              </div>
                                              <div class="value">
                                                  <ng-container *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                      <span>{{featureValues.value.replace('to', '&mdash;').replace('from', '')}}</span>
                                                  </ng-container>

                                                  <ng-container *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                      <span>{{featureValues.value}}</span>
                                                  </ng-container>
                                              </div>
                                          </div>
                                      </ng-container>
                                  </ng-container>
                              </ng-container>
                          </ng-container>
                        </ng-template>
                  </div>
                 <div class="accordionContent characteristics" *ngIf="(tabToShow != 'Overview' && specification.entity != 'Overview') && (tabToShow != 'Regulatory Info' && specification.entity != 'Regulatory Info') && (tabToShow != 'Application Fit' && specification.entity != 'Application Fit')">
                        <ng-container *ngFor="let specification of products.specifications">
                            <div class="content-area" *ngIf="tabToShow == specification.entity">
                          <div class="dataRow">
                          <ng-container *ngIf="tabToShow != 'Physical & Chemical Characteristics' && specification.entity != 'Physical & Chemical Characteristics';else other_contentPhysical">
                            <div class="dataColumn" *ngFor="let entry of specification.information">
                            <div class="attribute">{{ entry.attribute }}</div>
                            <div class="value">{{ entry.value }}</div>
                            </div>
                          </ng-container>
                          </div>
                            </div>
                        </ng-container>

                      <ng-template #other_contentPhysical>
                        <ng-container *ngIf="product$ | async as productl">
                            <ng-container *ngIf="productl.doehlerCatalogConfigData != undefined">
                                <ng-container *ngFor="let specificationsPhysicalChemicalCharacteristicsDCatalog of  productl.doehlerCatalogConfigData.productDetailSpecificationsPhysicalChemicalCharacteristicsDCatalog">
                                    <ng-container  *ngFor="let featureValues of specificationsPhysicalChemicalCharacteristicsDCatalog.featureData.featureValues">
                                        <div class="dataColumn">
                                            <div class="attribute">
                                                {{specificationsPhysicalChemicalCharacteristicsDCatalog.featureData.name}}
                                            </div>
                                            <div class="value">
                                                <ng-container *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                    <span>{{featureValues.value.replace('to', '&mdash;').replace('from', '')}}</span>
                                                </ng-container>
                                                <ng-container *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                    <span>{{featureValues.value}}</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                      </ng-template>
                    </div>

                  <div class="accordionContent application-fit-tab" *ngIf="(tabToShow != 'Overview' && specification.entity != 'Overview') && (tabToShow != 'Regulatory Info' && specification.entity != 'Regulatory Info') && (tabToShow != 'Physical & Chemical Characteristics' && specification.entity != 'Physical & Chemical Characteristics')">
                      <div class="accordionItem active categoryList">
                          <ng-container *ngIf="product$ | async as productl">
                              <ng-container *ngIf="productl.doehlerCatalogConfigData != undefined">
                                  <div class="accordionContent categoryList">
                                      <div class="application-fit-category-title" *ngIf="productl.doehlerCatalogConfigData.productDetailSpecificationsApplicationFitDCatalog.length > 0">
                                          <ng-container *ngFor="let specificationsApplicationFitDCatalog of productl.doehlerCatalogConfigData.productDetailSpecificationsApplicationFitDCatalog;let indexOfelement=index;">
                                              <div class="category-title-tab static-tab" *ngIf="visibleText(productl.doehlerCatalogConfigData.productDetailSpecificationsApplicationFitDCatalog, 'Beverage')">
                                                  Food
                                              </div>
                                              <div class="category-title-tab" [class.active]="contentId === 'content_' +indexOfelement" id="content_{{indexOfelement}}" (click)="tabChangesApplicationFit('content_'+indexOfelement)">
                                                  {{specificationsApplicationFitDCatalog.level1Categories.level1CategoryName}}
                                              </div>

                                              <div class="category-title-tab static-tab"  *ngIf="visibleText(productl.doehlerCatalogConfigData.productDetailSpecificationsApplicationFitDCatalog, 'Food')">
                                                  Beverage
                                              </div>
                                          </ng-container>
                                      </div>

                                      <div class="application-fit-category-item">
                                          <ng-container *ngFor="let specificationsApplicationFitDCatalog of productl.doehlerCatalogConfigData.productDetailSpecificationsApplicationFitDCatalog;let indexOfelement=index;">

                                              <ng-container *ngFor="let subCategoryList of removeDuplicates(specificationsApplicationFitDCatalog.level1Categories.subCategoryList, 'level3CategoryName')">
                                                  <div class="category-item" id="content_{{indexOfelement}}" *ngIf="contentId === 'content_'+indexOfelement">
                                                      <img src="{{renderImage(subCategoryList.level3CategoryImage)}}" *ngIf="renderImage(subCategoryList.level3CategoryImage)"/>
                                                      <h2>{{subCategoryList.level3CategoryName}}</h2>
                                                      <ul>
                                                          <ng-container *ngFor="let level5Categories of subCategoryList.level5CategoryName">
                                                              <li>{{level5Categories}}</li>
                                                          </ng-container>
                                                      </ul>
                                                  </div>
                                              </ng-container>

                                          </ng-container>
                                      </div>


                                  </div>
                              </ng-container>
                          </ng-container>
                      </div>
                  </div>
                </div>
            </ng-container>

        </div>
    </div>
  </div>
<div class="spacer"></div>

