<div *ngIf="product$ | async as product">
  <div *ngIf="product.productReferences[0]">
    <h2>Similar products</h2>
  </div>
</div>

<cx-carousel [title]="title$ | async" [items]="items$ | async" [template]="carouselItem"> </cx-carousel>
<ng-template #carouselItem let-item="item">
  <a tabindex="0">
    <div class="similar-products-section">
      <div class="row" style="display:table !important;">
            <div class="item-box">
                <a [href]="getCustomSimilarProductUrl(item.code,item.name)" target="blank">
                  <cx-media class="image" [container]="item.images?.PRIMARY.thumbnail"></cx-media>
                </a>
            
                <!-- <cx-media
                  *ngIf="item.images?.PRIMARY"
                  [container]="item.images.PRIMARY"
                  format="thumbnail"
                  class="image">
                </cx-media> -->

                <div class="details">
                  <h3>{{ item.name }}</h3>
                <div class="product-id">{{item.code }}</div>
                  
                  <ng-container *ngFor="let productHighlightDCatalog of item.productHighlightDCatalog">
                      <div class="attribute-list">
                        <ul>
                          <li>
                            <div class="attribute-icon">
                              <img [src]="getCustomVariantThumbnailUrl(productHighlightDCatalog.iconPath)"/>
                            </div>
    
                            <div *ngIf="productHighlightDCatalog.featureData.range">
                              <div class="attribute-value">
                                <ng-container *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                  <div class="details">
                                    {{productHighlightDCatalog.featureData.name}}| <span>{{featureValues.value}}</span>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
    
                            <div *ngIf="!productHighlightDCatalog.featureData.range">
                              <div class="attribute-value">
                                <ng-container *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
                                  <div class="details">
                                    {{featureValues.value}}
                                  </div>
                                </ng-container>
                              </div>
                            </div>

                          </li>
                        </ul>
                      </div>
                  </ng-container>
                </div>
            </div>
      </div>
    </div>
  </a>
</ng-template>
