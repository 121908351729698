<div class="content-wrapper" id="top">
  <section class="product-detail-new">
        <div class="inner">
          <div class="goBackArrow" href="javascript:void(0)" (click)="getHomePage()"></div>
          <div class="product-stage">
            <ng-container *ngIf="product$ | async as product">
                <div class="productHighlights">
                  <h2 class="productTitle">{{product.name}}</h2>
                  <h3 class="productNumber">{{product.code}}</h3>
                    <ng-container *ngIf="product.images && product.images.length > 0 && product.images != undefined">
                        <div class="productImage mobile"  [ngStyle]="setBackgroundImage(product.images?.PRIMARY.product.url)"></div>
                    </ng-container>
                    <ul class="checkMarkList">
                    <li class="product-benefits" [innerHTML]="product.productBenefits"></li>
                  </ul>
                  <div class="buttonRow">
                    <button type="button" (click)="openRequestASampleCommand(product.code,product.name)">
                        Request a  sample  <fa-icon [icon]="faAngleDoubleRight"></fa-icon>
                    </button>
                      <button type="button" (click)="openRequestAQuoteCommand(product.code,product.name)">
                      Request quote  <fa-icon [icon]="faAngleDoubleRight"></fa-icon>
                    </button>
                    <button type="button" class="outline" (click)="openFindOutMoreCommand(product.code,product.name)">
                        Find out more  <fa-icon [icon]="faAngleDoubleRight"></fa-icon>
                    </button>
                      <ng-container *ngIf="this.isFavourite == false">
                          <button id="addToFav" class="addToFav fav" type="button" (click)="changeFavourite(product.code,product.name)">
                              <div class="tooltips">
                                  <div class="favorite-img"></div>
                                  <span class="tooltiptext">Add to My Favorite Products</span>
                              </div>
                          </button>
                      </ng-container>
                      <ng-container *ngIf="this.isFavourite == true">
                          <button id="addToFav" class="addToFav addedToFavorite" type="button" (click)="changeFavourite(product.code,product.name)">
                              <div class="tooltips">
                                  <div class="favorite-img"></div>
                                  <span class="tooltiptext">Added to My Favorite Products</span>
                              </div>
                          </button>
                      </ng-container>
                  </div>
                </div>
                <!--<div class="productImage" style="background-image: url('/assets/images/product-catalogue/orange-visual.jpg')"></div>-->
                <ng-container *ngIf="product.images && product.images.length > 0 && product.images != undefined">
                    <div class="productImage desktop"  [ngStyle]="setBackgroundImage(product.images?.PRIMARY.product.url)"></div>
                </ng-container>
            </ng-container>
          </div>
        </div>
  </section>
</div>
<ng-container *ngIf="product$ | async as product">
    <jw-modal id="addToFavorite" class="wideModal">
        <div class="jw-modal-close-icon">
           <button type="cancel" class="close-favorite" (click)="closeModal('addToFavorite')"></button>
        </div>
        <div class="jw-modal-inner" id="plpModal">
            <h3 class="move-to-collection">Add to collection</h3>
            <p class="please-select-the-collection">Add the product to a collection to organize your favourite products.</p>
            <form [formGroup]="saveToCollection" id="saveWishlistForm">
                <div id="plpAddToCollection">
                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                        <ng-container *ngIf="wishlists.values.length == 0">
                            <div class="form-group">
                                <label class="radio-inline">
                                    <input class="form-control" type="radio" name="wishlistCodePost" value="General Collection" [checked]=""/>
                                    <div class="collection-info">
                                        <span class="collection">General Collection</span>
                                        <div class="last-update">
                                            <div class="last-changed">Last change:
                                                <span>0</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wishlists.values.length > 0">
                            <ng-container *ngFor="let item of wishlists.values">
                                <div class="form-group">
                                    <label class="radio-inline">
                                        <input class="form-control wishlist-class" type="radio" name="wishlistCodePost" value="{{item.pk}}" [checked]="item.name === 'General Collection'"/>
                                        <div class="collection-info">
                                            <span class="collection">{{item.name}}</span>
                                            <div class="last-update">
                                                <div class="last-changed">Last change:
                                                    <span>{{item.modifiedDate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="buttonRow favoriteButtons-desktop">
                    <button type="button" class="create" (click)="createFavorite('requestCreateFavorite')"><div class="create-img"></div><span class="button-name">Create a collection</span></button>
                </div>
                <div class="buttonRow desktop-favoriteButtons">
                    <div class="button-container">
                        <button type="cancel" class="cancel" (click)="closeModal('addToFavorite')">Cancel</button>
                        <button (click)="favoriteSubmitForm()" class="submitButton">Add</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <div class="button-container">
                        <button (click)="favoriteSubmitForm()" class="submitButton">Add</button>
                        <button type="cancel" class="cancel" (click)="closeModal('addToFavorite')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </jw-modal>
    <jw-modal id="requestCreateFavorite">
        <div class="jw-modal-close-icon">
            <button type="cancel" class="close-favorite" (click)="closeModal('requestCreateFavorite')"></button>
        </div>
        <div class="jw-modal-inner" id="plpCreateModal">
            <h3>Create a collection</h3>
            <div id="plpCreateCollection">
                <form id="createWishlistForm" autocomplete="off" [formGroup]="createCollectionForm">
                    <div class="form-group">
                        <h4>Collection Name</h4>
                        <input type="text" id="saveWishlistName-{{product.code}}" name="wishlistNamePost" placeholder="New collection name" maxlength="20" class="form-control">
                    </div>
                    <div class="buttonRow desktop-favoriteButtons">
                        <label id="createErrorDesktop" class="wishlist-label" for="saveWishlistName-{{product.code}}"><div class="item-image"></div><p>This collection name already exists.</p></label>
                        <div class="button-container">
                            <button type="cancel" class="cancel" (click)="closeModal('requestCreateFavorite')">Cancel</button>
                            <button (click)="submitCreateForm(product.code,product.name)" class="submitButton">Save</button>
                        </div>
                    </div>
                    <div class="buttonRow mobile-favoriteButtons">
                        <label id="createErrorMobile" class="wishlist-label" for="saveWishlistName-{{product.code}}"><div class="item-image"></div><p>This collection name already exists.</p></label>
                        <div class="button-container">
                            <button (click)="submitCreateForm(product.code,product.name)" class="submitButton">Save</button>
                            <button type="cancel" class="cancel" (click)="closeModal('requestCreateFavorite')">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </jw-modal>
    <jw-modal id="requestToAddFavoriteList">
        <div class="jw-modal-close-icon">
            <button type="cancel" class="close-favorite" (click)="closeModal('requestToAddFavoriteList')"></button>
        </div>
        <div class="jw-modal-inner" id="plp-createFavorite">
            <div class="form-content second-modal">
                <div class="alert-wrapper">
                    <div class="alert-container">
                        <div class="alert-header">
                            <div class="alert-icon success"></div>
                            <h3>Added to My Favorite Products</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </jw-modal>
    <jw-modal id="removeFromFavorite" class="wideModal">
        <div class="jw-modal-close-icon">
            <button type="cancel" class="close-favorite" (click)="closeModal('removeFromFavorite')"></button>
        </div>
        <div class="jw-modal-inner" id="plpModal">
            <h3>Remove From collection</h3>
            <div id="plpRemoveFromFavorite">
                <form [formGroup]="removeFromCollection" id="removeFromWishlistForm">
                    <p class="please-select-the-collection">Are you sure you want to remove <span>{{favouriteProductName}}</span> from your list?</p>
                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                        <ng-container *ngIf="wishlists.values.length == 0">
                            <div class="form-group" style="display: none;">
                                <label class="radio-inline">
                                    <input class="form-control" type="radio" name="wishlistCodePost" value="General Collection" [checked]=""/>
                                    <span class="collection">General Collection</span>
                                </label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wishlists.values.length > 0">
                            <ng-container *ngFor="let item of wishlists.values">
                                <div class="form-group" style="display: none;">
                                    <label class="radio-inline">
                                        <input class="form-control wishlist-class" type="radio" name="wishlistCodePost" value="{{item.pk}}" [checked]=""/>
                                        <span class="collection">{{item.name}}</span>
                                        <div class="last-update">
                                            <div class="last-changed">Last change:
                                                <span>{{item.modifiedDate}}</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <div class="buttonRow desktop-favoriteButtons">
                        <div class="button-container">
                            <button type="cancel" class="cancel" (click)="closeModal('removeFromFavorite')">Cancel</button>
                            <button (click)="removeFavorite('removeFavorite')" class="submitButton">Apply</button>
                        </div>
                    </div>
                    <div class="buttonRow mobile-favoriteButtons">
                        <div class="button-container">
                            <button (click)="removeFavorite('removeFavorite')" class="submitButton">Apply</button>
                            <button type="cancel" class="cancel" (click)="closeModal('removeFromFavorite')">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </jw-modal>
    <jw-modal id="removeFavorite">
        <div class="jw-modal-close-icon">
            <button type="cancel" class="close-favorite" (click)="closeModal('removeFavorite')"></button>
        </div>
        <div class="jw-modal-inner" id="plp-createFavorite">
            <div class="form-content second-modal">
                <div class="alert-wrapper">
                    <div class="alert-container">
                        <div class="alert-header">
                            <div class="alert-icon success"></div>
                            <h3>Removed from My Favorite Products</h3>
                        </div>
                    </div>
                </div>
                <form [formGroup]="removeFromCollection" id="removeFromWishlistForm" style="display: none;">
                    <div id="plpAddToCollection">
                        <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                            <ng-container *ngIf="wishlists.values.length == 0">
                                <div class="form-group">
                                    <label class="radio-inline">
                                        <input class="form-control" type="radio" name="wishlistCodePost" value="General Collection" [checked]=""/>
                                        <span class="collection">General Collection</span>
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="wishlists.values.length > 0">
                                <ng-container *ngFor="let item of wishlists.values">
                                    <div class="form-group">
                                        <label class="radio-inline">
                                            <input class="form-control wishlist-class" type="radio" name="wishlistCodePost" value="{{item.pk}}" [checked]=""/>
                                            <span class="collection">{{item.name}}</span>
                                            <div class="last-update">
                                                <div class="last-changed">Last change:
                                                    <span>{{item.modifiedDate}}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </form>
            </div>
        </div>
    </jw-modal>
</ng-container>
<div class="spacer"></div>



