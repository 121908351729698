import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'doehler-section4a',
  templateUrl: './section4a.component.html',
  styleUrls: ['./section4a.component.scss'],
})
export class Section4aComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  product = {
    number: 'K12094',
    title: 'Orange Oil 5-Fold',
    visualURL: 'product-catalogue/orange-visual.jpg',
    productStatement: 'Peely Ripe Juice',
    benefits: [
      'Different orange colours for applications available',
      'Diversity of orange colours ensures the compatibility',
      'Differen orange colours for applications',
    ],
    target: '',
    highlights: [
      { label: 'Orange', icon: 'circle-regular-orange.svg' },
      { label: 'Viscous', icon: 'doehler-viscous.svg' },
      { label: 'Acidity', icon: 'doehler-acidity.svg', content: '2.5 - 4.0g' },
      { label: 'Organic', icon: 'doehler-organic.svg' },
    ],
    specifications: [
      {
        entity: 'overview',
        information: [
          { attribute: 'Test-Attribute', value: 'Test-Value' },
          { attribute: 'Test-Attribute2', value: 'Test-Value2' },
          { attribute: 'Test-Attribute3', value: 'Test-Value3' },
        ],
      },
      {
        entity: 'characteristics',
        information: [
          { attribute: 'Acidity c. as CAA ph=8.1', value: '2.5 - 4.0 g/100g' },
          { attribute: 'Brix uncorr. = refractometric', value: '89.0 - 94.0 Brix' },
          { attribute: 'Consistency', value: 'Viscous' },
          { attribute: 'Acidity c. as CAA ph=8.1', value: '2.5 - 4.0 g/100g' },
          { attribute: 'Brix uncorr. = refractometric', value: '89.0 - 94.0 Brix' },
          { attribute: 'Consistency', value: 'Viscous' },
          { attribute: 'Acidity c. as CAA ph=8.1', value: '2.5 - 4.0 g/100g' },
          { attribute: 'Brix uncorr. = refractometric', value: '89.0 - 94.0 Brix' },
          { attribute: 'Consistency', value: 'Viscous' },
        ],
      },
      {
        entity: 'behavior',
        information: [
          { attribute: 'Test-Attribute', value: 'Test-Value' },
          { attribute: 'Test-Attribute2', value: 'Test-Value2' },
          { attribute: 'Test-Attribute3', value: 'Test-Value3' },
          { attribute: 'Test-Attribute', value: 'Test-Value' },
          { attribute: 'Test-Attribute2', value: 'Test-Value2' },
          { attribute: 'Test-Attribute3', value: 'Test-Value3' },
        ],
      },
      {
        entity: 'assortment',
        information: [
          { attribute: 'Test-Attribute Assortment', value: 'Test-Value' },
          { attribute: 'Test-Attribute Assortment2', value: 'Test-Value2' },
          { attribute: 'Test-Attribute Assortment3', value: 'Test-Value3' },
        ],
      },
      {
        entity: 'application',
        information: [
          { attribute: 'Test-Attribute Application', value: 'Test-Value' },
          { attribute: 'Test-Attribute Application2', value: 'Test-Value2' },
          { attribute: 'Test-Attribute Application3', value: 'Test-Value3' },
        ],
      },
      {
        entity: 'documents',
        information: [
          { attribute: 'Test-Attribute Documents', value: 'Test-Value' },
          { attribute: 'Test-Attribute Documents2', value: 'Test-Value2' },
          { attribute: 'Test-Attribute Documents3', value: 'Test-Value3' },
        ],
      },
    ],
    useOfApplication: {
      heroImageURL: 'product-catalogue/hero-image-orange.png',
      colorCode: '#feb600',
      shortDescription:
        'One of the most popular flavours for sweet food products. Citrus fruits offer a more diverse range of taste nuances than almost any other fruit category.',
      claim: '100% Nature | Fruit | Purity',
      rowImageURLs: [
        'product-catalogue/product-inspiration1.png',
        'product-catalogue/product-inspiration2.png',
        'product-catalogue/product-inspiration3.png',
        'product-catalogue/product-inspiration4.png',
      ],
    },
    similarProducts: [
      {
        imageSRC: 'product-catalogue/erdbeer-milch.jpg',
        title: 'Flavoured Milk Drink',
        id: 'D26616',
        attibutes: [
          { iconSVG: '/assets/icons/circle-regular-pink.svg', label: 'Pink' },
          { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Viscous' },
          { iconSVG: '/assets/icons/doehler-acidity.svg', label: 'Acidity 2.5 - 4.0g/100g' },
        ],
      },
      {
        imageSRC: 'product-catalogue/erdbeer-milch.jpg',
        title: 'Flavoured Milk Drink',
        id: 'D26616',
        attibutes: [
          { iconSVG: '/assets/icons/circle-regular-orange.svg', label: 'Orange' },
          { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Viscous' },
          { iconSVG: '/assets/icons/doehler-acidity.svg', label: 'Acidity 2.5 - 4.0g/100g' },
        ],
      },
      {
        imageSRC: 'product-catalogue/erdbeer-milch.jpg',
        title: 'Flavoured Milk Drink',
        id: 'D26616',
        attibutes: [
          { iconSVG: '/assets/icons/circle-regular-white.svg', label: 'Pink' },
          { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Viscous' },
          { iconSVG: '/assets/icons/doehler-acidity.svg', label: 'Acidity 2.5 - 4.0g/100g' },
        ],
      },
      {
        imageSRC: 'product-catalogue/erdbeer-milch.jpg',
        title: 'Flavoured Milk Drink',
        id: 'D26616',
        attibutes: [
          { iconSVG: '/assets/icons/circle-regular-white.svg', label: 'Pink' },
          { iconSVG: '/assets/icons/doehler-viscous.svg', label: 'Viscous' },
          { iconSVG: '/assets/icons/doehler-acidity.svg', label: 'Acidity 2.5 - 4.0g/100g' },
        ],
      },
    ],
  };

  setBackgroundIcon(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/icons/${sImgSrcUrl})`,
    };
  }

  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/images/${sImgSrcUrl})`,
    };
  }

  goTo(target: string) {
    console.log(target);
  }
}
