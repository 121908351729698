import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Product, OccConfig } from '@spartacus/core';
import { CurrentProductService ,ICON_TYPE } from '@spartacus/storefront';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { PRODUCTDATA } from '../../doehler-searchPage/static-data/product-data';

@Component({
  selector: 'doehler-section3',
  templateUrl: './section3.component.html',
  styleUrls: ['./section3.component.scss']
})
export class Section3Component implements OnInit {
  constructor(private currentProductService: CurrentProductService,
              private config: OccConfig
              ) { }

  iconTypes = ICON_TYPE;
  product$: Observable<Product> = this.currentProductService.getProduct();

  products = {
    specifications: [
      {
        entity: "Overview",
        information: [
        ],
      },
      {
        entity: "Regulatory Info",
        information: [ 
        ],
      },
      {
        entity: "Physical & Chemical Characteristics",
        information: [
        ],
      },
      {
        entity: "Application Fit",
        information: [
        ],
      }
    ],
  };

  productData = PRODUCTDATA;

  groupTabToShow = '';

  tasteTabToShow = '';
  tabToShow = "Overview";
  faAngleDoubleRight = faAngleDoubleRight;

  showTasteTab(name: string) {
    this.tasteTabToShow = name;
  }

  ngOnInit(): void {
  }

  setBackgroundIcon(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/icons/${sImgSrcUrl})`
    };
  }
  showTab(name: string) {
    if( this.tabToShow == name) {
      this.tabToShow = "";
    } else {
      this.tabToShow = name;
    }
  }

  contentId: any = "content_0";
  tabChangesApplicationFit(ids: any){
    this.contentId = ids;
    console.log(this.contentId);
  }

  removeDuplicates (array, key) {
    return array.reduce((arr, item) => {
      const removed = arr.filter(i => i[key] !== item[key]);
      return [...removed, item];
    }, []);
  }

  findCategoryName (array, value) {
    return array.filter(item => item.level3CategoryName === value);
  }

  renderImage(level3CategoryImage){
    return level3CategoryImage && level3CategoryImage[0] && level3CategoryImage[0].mediaPath ?  `${this.config.backend.occ.baseUrl}${level3CategoryImage[0].mediaPath}`:"/assets/images/empty-image.png";
  }

  visibleText(data,value="Food") {
    if (data.length === 1) {
       return data?.[0]?.level1Categories?.level1CategoryName === value ? true : false;
    }
    return false;
    }

  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/images/${sImgSrcUrl})`
    };
  }

  goTo(target: string) {
    console.log(target);
  }

  //9002 portundaki bir resmi almak icin
  getFeaturesDataValueIconUrl(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${this.config.backend.occ.baseUrl}${sImgSrcUrl})`,
    };
  }

   //html icerisinde similir productların resimlerini göstermek icin kullanildi
   getFeaturesDataValueUrl(thumbnailUrl: string): string {
    return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
  }

}
