<!--<div class="container-fluid">-->
<div class="content-wrapper">
  <!-- <div class="product-highlights-carousel">-->
  <div class="inner">
    <h3 class="highlights-specifications-setting specification-title">Product Features</h3>
    <ng-container *ngIf="product$ | async as productHighlight">
      <a tabindex="0">
        <div class="highlights-section">
          <ng-container *ngIf="productHighlight.doehlerCatalogConfigData != undefined">
            <ng-container *ngIf="productHighlight.doehlerCatalogConfigData.productDetailProductHighlightDCatalog != undefined">
              <ng-container *ngFor="let productDetailProductHighlightDCatalog of  productHighlight.doehlerCatalogConfigData.productDetailProductHighlightDCatalog">
                <div class="highlight-box">
                  <div class="facetName">
                    {{productDetailProductHighlightDCatalog.featureData.name}}
                  </div>
                  <div class="details">
                    <ng-container *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                      <ng-container *ngIf="featureValues.value.indexOf(' to ') > -1">
                        <span>{{featureValues.value.replace('to', '&mdash;').replace('from', '')}}</span>
                      </ng-container>

                      <ng-container *ngIf="featureValues.value.indexOf(' to ') == -1">
                        <span>{{featureValues.value}}</span>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="productDetailProductHighlightDCatalog.featureData.name == 'Colour Unit (E1 %, 1 CM)'">
                      <div class="addition">
                        (method: 530nm, pH 3)
                      </div>
                    </ng-container>
                    <ng-container *ngIf="productDetailProductHighlightDCatalog.featureData.name == 'Colour Content'">
                      <div class="addition">
                        (method: 610nm)
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </a>
    </ng-container>
  </div>
  <!--  </div>-->
</div>
<div class="spacer"></div>
<!-- </div>-->


<!--ESKI KODLAR image - range olayları varken-->

<!-- Eger icon yoksa ya 1-3 gibi bir yapıdır yada cidden iconu yoktur bunları birbirinden ayırmak icin range değerine bakmak yeterli -->
<!-- <div *ngIf="productHighlightDCatalog.iconPath == undefined">
 !--Eğer range = true ise 1-3 gibi bir değerdir ve o zaman g/100g yapısı kullanılmalı-->
<!-- <div *ngIf="productHighlightDCatalog.featureData.range">
  <ng-container *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
    <div class="image-value">
      {{featureValues.value}}
    </div>
  </ng-container>
  <div class="details">
    {{productHighlightDCatalog.featureData.name}}
  </div>
</div> -->
<!--Eğer range=false ise normal bir değerdir orange vs. o zaman icon olmadıgı icin o alan boş kalacak-->
<!-- <div *ngIf="!productHighlightDCatalog.featureData.range"> -->
<!--icon yok ama değer üste kaymasın diye icon alanını yine ekliyorum-->
<!-- <div class="image" [ngStyle]="getFeaturesDataValueIconUrl(productHighlightDCatalog.iconPath)"></div> -->
<!-- <ng-container *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
  <div class="details">
    {{featureValues.value}}
  </div>
</ng-container>
</div>
</div> -->
<!--Eğer icon varsa iconu zaten yukarıda ekledik değerinide burada ekleyeceğiz YOKSA icon ile değer de sağa sola kayma oluyor-->
<!-- <div *ngIf="productHighlightDCatalog.iconPath != undefined">
  <ng-container *ngFor="let featureValues of productHighlightDCatalog.featureData.featureValues">
    <div class="details">
      {{featureValues.value}}
    </div>
  </ng-container>
</div> --> 