import { Component, OnInit } from '@angular/core';
import { CmsProductReferencesComponent, OccConfig, Product, ProductReference, ProductReferenceService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'doehler-section4c',
  templateUrl: './section4c.component.html',
  styleUrls: ['./section4c.component.scss']
})
export class Section4cComponent implements OnInit {

  product$: Observable<Product> = this.current.getProduct();
  
  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 4,
    "dots" : true,
    "infinite" : false,
    //"autoRotate" : true,
    //"autoRotateAfter" : 5000,
    "autoplay" : false,
    //"autoplaySpeed" : 1000000000,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
  };

  title$ = this.component.data$.pipe(map((d) => d?.title));

  private currentProductCode$: Observable<string> = this.current.getProduct().pipe(
    filter(Boolean),
    map((p: Product) => p.code),
    distinctUntilChanged(),
    tap(() => this.referenceService.cleanReferences())
  );

  items$: Observable<Observable<Product>[]> = combineLatest([this.currentProductCode$, this.component.data$]).pipe(
    switchMap(([code, data]) => this.getProductReferences(code, data?.productReferenceTypes))
  );

  
  constructor(
    protected component: CmsComponentData<CmsProductReferencesComponent>,
    protected current: CurrentProductService,
    protected referenceService: ProductReferenceService,
    private config: OccConfig
      ) {}

  private getProductReferences(code: string, referenceType: string): Observable<Observable<Product>[]> {

    this.referenceService.loadProductReferences(code, "OTHERS");

    return this.referenceService.getProductReferences(code, referenceType).pipe(
      filter(Boolean),
      map((refs: ProductReference[]) => refs.map((ref) => of(ref.target)))
    );
  }


  //html icerisinde similir productların resimlerini göstermek icin kullanildi
  getCustomVariantThumbnailUrl(thumbnailUrl: string) : object{
    console.log(`${this.config.backend.occ.baseUrl}${thumbnailUrl}`);
    //return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
    return {
      'background-image': `${this.config.backend.occ.baseUrl}${thumbnailUrl}`,
    };
  }

  //html icerisinde similir productların resimlerini göstermek icin kullanildi
  getFeaturesDataValueUrl(thumbnailUrl: string): string {
    return  `${this.config.backend.occ.baseUrl}${thumbnailUrl}`;
  }

  //9002 portundaki bir resmi almak icin
  getFeaturesDataValueIconUrl(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${this.config.backend.occ.baseUrl}${sImgSrcUrl})`,
    };
  }

  setBackgroundIcon(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/icons/${sImgSrcUrl})`,
    };
  }
  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(/assets/images/${sImgSrcUrl})`,
    };
  }

  ngOnInit(): void {

    /*  this.product$.subscribe(res => {
      console.log(res.productReferences[0]);

      for(let i=0;i<res.productReferences.length;i++){
        console.log(res.productReferences[i].target.name);
        console.log(res.productReferences[i].target.code);
      } 
   }); */

  }

}
